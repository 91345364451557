<template>
  <footer
    class="d-flex justify-content-around align-items-center text-center custom-footer"
    style="background-color: #0a112b; padding: 20px"
  >
    <div class="mb-3">
      <a
        href="https://privacypolicy.ibhrms.com/"
        class="text-light"
        style="text-decoration: none"
        >Terms & Condition | Privacy Policy</a
      >
    </div>
    <div class="mb-3 text-white">
      <img
        src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconPhone.png"
        alt="Phone Icon"
        style="height: 18px"
      />&nbsp; +91 7201933133
    </div>
    <div>
      <a
        href="https://www.facebook.com/Redjinnifood/"
        class="text-light text-decoration-none"
        ><img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/fb.png"
          alt="Facebook"
          style="height: 20px; margin-right: 10px"
      /></a>
      <a
        href="https://www.instagram.com/redjinniindia/"
        class="text-light text-decoration-none"
        ><img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/insta.png"
          alt="Instagram"
          style="height: 20px; margin-right: 10px"
      /></a>
      <a
        href="https://www.linkedin.com/company/redjinni/"
        class="text-light text-decoration-none"
        ><img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/linkdin.png"
          alt="LinkedIn"
          style="height: 20px; margin-right: 10px"
      /></a>
      
      <a
        href="https://twitter.com/redjinnisurat?lang=en"
        class="text-light text-decoration-none"
        ><img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/tweeter.png"
          alt="Twitter"
          style="height: 20px; margin-right: 10px"
      /></a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped>
@media (max-width: 948px) {
  .custom-footer {
    flex-direction: column;
  }
}
</style>
