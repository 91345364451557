<template>
  <body>
    <div class="container" style="background-color: white; padding: 4rem">
      <!-- <h2 style="font-size: 3rem">IBSalesforce: Privacy Policy</h2> -->
      <h1>Privacy Policy for ibsales App</h1>
      <p>
        This Privacy Policy governs the manner in which the ibsales App
        collects, uses, maintains, and discloses information collected from
        users (each, a "User") of the ibsales App ("Application"). This privacy
        policy applies to the Application and all products and services offered
        by the ibsales App.
      </p>

      <h2>Information Collection and Use:</h2>
      <p>
        The ibsales App collects personal information from Users in order to
        provide them with our HR-related services. We may collect personal
        identification information from Users when they register on the
        Application, fill out a form, or participate in other activities or
        features that we make available on our Application. This information may
        include name, email address, mailing address, phone number, social
        security number, job title, and other relevant information. We may also
        collect non-personal identification information, such as the type of
        computer or mobile device used to access our Application, and technical
        information about the User's means of connection to our Application.
      </p>

      <h2>Web Browser Cookies:</h2>
      <p>
        We may use cookies to enhance User experience and to track information
        about Users. Users may choose to set their web browser to refuse cookies
        or to alert them when cookies are being sent. If they do so, some parts
        of the Application may not function properly.
      </p>

      <h2>How We Use Collected Information:</h2>
      <p>
        The ibsales App uses the collected information to manage human resource
        functions, including payroll, benefits administration, and other
        employee-related services. We may also use the information to
        personalize User experience, improve our Application, and send periodic
        emails regarding HR-related news, updates, and other relevant
        information.
      </p>

      <h2>Sharing Your Personal Information:</h2>
      <p>
        We do not sell, trade, or rent Users' personal identification
        information to others. However, we may share generic aggregated
        demographic information with our business partners, trusted affiliates,
        and advertisers for the purposes outlined above.
      </p>

      <h2>Security:</h2>
      <p>
        The ibsales App takes reasonable measures to protect Users' personal
        information from unauthorized access, use, or disclosure. However, we
        cannot guarantee the security of Users' information.
      </p>

      <h2>Changes to This Privacy Policy:</h2>
      <p>
        The ibsales App has the discretion to update this privacy policy at any
        time. When we do, we will post a notification on the main page of our
        Application and revise the updated date at the bottom of this page. We
        encourage Users to frequently check this page for any changes to stay
        informed about how we are helping to protect the personal information we
        collect.
      </p>

      <h2>Your Acceptance of These Terms:</h2>
      <p>
        By using this Application, you signify your acceptance of this policy.
        If you do not agree to this policy, please do not use our Application.
        Your continued use of the Application following the posting of changes
        to this policy will be deemed your acceptance of those changes.
      </p>

      <h2>Contacting Us:</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        this Application, or your dealings with this Application, please contact
        us at<a href="#">contact@infinitybrains.com</a>.
      </p>

      <p>Updated on 26/06/24</p>
    </div>
  </body>
</template>

<!-- Privacy Policy  -->

<script>
export default {
  name: "PriivacyPolicy",
};
</script>

<style scoped>
p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.2rem;
  color: #666;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.container {
  width: 80%;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
  color: #444;
}

h1 {
  font-size: 2em;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
}

h2 {
  font-size: 1.5em;
  margin-top: 20px;
}

p {
  line-height: 1.6;
  margin: 10px 0;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
