<template>
  <div class="d-flex flex-column min-vh-100">
    <div
      class="flex-grow-1 d-flex align-items-center justify-content-center flex-column"
    >
      <div class="container rounded shadow p-3 bg-body w-50 rounded-3">
        <div
          class="inner-container d-flex align-items-center justify-content-center flex-column"
        >
          <h2 class="m-0" style="font-size: 1.8rem; font-weight: 610">
            Verify Email
          </h2>
          <p class="m-0">
            <span
              class="font-weight-bold mx-1"
              style="margin-top: -2%; font-size: 0.9rem"
              >Enter Your Company Email For Verification</span
            >
          </p>
        </div>

        <div class="form my-3">
          <div class="row">
            <div class="col-lg-6 col-md-12 mb-3 w-100">
              <!-- <label for="email">Email Address</label> -->
              <input
                type="text"
                id="email"
                class="form-control"
                placeholder="example@gmail.com"
                v-model="email"
              />
            </div>
          </div>

          <p class="font-size-1rem text-danger" :class="messageClass">
            {{ formError }}
          </p>

          <!-- Verify Email Button -->
          <div class="text-center">
            <button
              class="btn btn-primary w-100"
              type="button"
              @click="verifyEmail"
              :disabled="isEmailVerified"
            >
              Verify Email
            </button>
          </div>
        </div>
      </div>

      <!-- Next Button (Visible only after email verification) -->
      <div class="text-center my-3 w-50 custom-next-btn">
        <button
          class="btn btn-primary w-100"
          type="button"
          @click="moveToNextStep"
          :disabled="!isEmailVerified"
        >
          <router-link
            to="/logoPayment"
            style="text-decoration: none; color: white"
            >Next</router-link
          >
        </button>
      </div>
    </div>
    <!-- Include Footer component -->
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "VerifyEmailPage",
  components: {
    Footer,
  },
  data() {
    return {
      email: "",
      formError: "",
      isEmailVerified: false,
      messageClass: "",
      userDetails: [],
      userPlanDetails: [],

      apiurl: "https://uatbackendsales.infinitybrains.com/public/api/",
      // apiurl: "pending",
    };
  },

  methods: {
    async verifyEmail() {
      if (!this.email.trim()) {
        this.formError = "Please enter your email address.";
        return;
      }

      try {
        // const authToken = process.env.VUE_APP_LOGO_PAYMENT_AUTH_TOKEN;

        const response = await axios.post(
          `${this.apiurl}verify-email-logo`,
          {
            email: this.email,
            plan_id: "70",
          }
          // {
          //   headers: {
          //     Authorization: `Bearer ${authToken}`,
          //     "Content-Type": "application/json",
          //   },
          // }
        );

        // console.log("API Response:", response.data.message);

        if (
          response.data.status === true &&
          response.data.message === "Email Verify successfully."
        ) {
          this.isEmailVerified = true;
          // Store the user information from the API response
          this.userDetails = response.data.data;
          this.userPlanDetails = response.data.plan;
          // console.log("user Details = ", this.userDetails);
          // console.log("user Plan Details = ", this.userPlanDetails);

          this.$store.commit("setPaymentDetails", {
            cgst: this.userPlanDetails.cgst,
            sgst: this.userPlanDetails.sgst,
            amount: this.userPlanDetails.amount,
            total_amount: this.userPlanDetails.total_amount,
            user_update_payment_status: this.userPlanDetails.id,
            user_email: this.userDetails.email,
            first_name: this.userDetails.first_name,
            last_name: this.userDetails.last_name,
            mobile_no: this.userDetails.mobile_no,
          });
        } else {
          this.formError = "User not found. Enter a valid email address.";
          setTimeout(() => {
            this.formError = "";
          }, 5000);
          console.error("Error verifying email else:", response.data.message);
        }
      } catch (error) {
        // console.log(error.response.data.message);
        this.formError = error.response.data.message;
        setTimeout(() => {
          this.formError = "";
        }, 5000);
        console.error("Error verifying email:", error);
      }
    },

    moveToNextStep() {
      if (this.isEmailVerified) {
        this.$router.push("/logoPayment");
      } else {
        this.formError = "Please verify your email first";
      }
    },
  },
};
</script>

<style scoped>
/* Add additional styles as needed for responsiveness */

@media (max-width: 620px) {
  .container {
    width: 70% !important;
  }

  .custom-next-btn {
    width: 70% !important;
  }
}

@media (max-width: 420px) {
  .container {
    width: 100% !important;
  }

  .custom-next-btn {
    width: 100% !important;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 30% !important;
  }

  .custom-next-btn {
    width: 30% !important;
  }
}
</style>
