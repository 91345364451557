<template>
  <div class="my-container-fluid   " style=" max-width: 100%; padding-left:6%; max-width: 100%; border-color: black">
    <div class="row  w-100 container " >
      <div class="col-7  ">
        <!-- <img src="../assets/Ibicon.svg" style="margin-top: 3rem" /> -->
        <router-link to="/">
        <img
          src="../assets/Ibicon.svg"
          class="logo"
          style=" margin-top: 3rem; z-index: 0; float: left"
      /></router-link>
      </div>
      <div class="col-5  text-center">
        <router-link to="/buy"><img class="logo2" src="../../ASSET/skip.svg" style="padding-left: 3%;
            padding-right: 3%;
            z-index: 0;
            margin-top: 5.7rem;
            margin-right: 7.5vh;
            float: right;
         
            color: white;
             "/></router-link>
        <div class="row" style="margin-top: 5rem;"></div>
        
        <div class="row h-25" >
          <div class="col     ">
            
            <!-- <label @click="reb()"
              class="btnb     h-100 w-100  text-center" 
              style="border-radius: 5px; color: white; background-color: blue"
              >EXISTING USER</label
            >
          </div>

          <div class="col     ">
            <router-link to="/buy" class="h-100">
              <label
                class="btnb     text-center h-100  newUser"
                style="
                  border-radius: 5px;
                  color: white;
                  width: 100%;
                  background-color: blue;
                "
                >NEW USER</label
              >
            </router-link> -->
            
          </div>
        </div>
      </div>
    </div>
    <div class="row    " >

      <div class="my-component col-lg-8 col-sm-12   " style="">
        <video class="video1"
      ref="videoPlayer"
      src="./../../src/assets/intro2.mp4"
      controls
      :poster="posterUrl"
      autoplay
      loop
      style="
      object-fit: fill;
            
            margin-top: 5rem;
            
            max-width: 93.5%;
            max-height:37rem;

      "
    >
  </video>
      <div class="col-12">

          <h2 style="margin-top: 2%;">IBT | Introduction Video</h2>
      </div>
      <div class="row">
  

  
</div>

<div class="row">
  <div class="col-lg-3 col-sm-6 smallVideo" style="padding: 0; margin: 0;">
    <video class="border video2"
      ref="videoPlayer"
      src="./../../src/assets/intro2.mp4"
      controls
      :poster="posterUrl"
      autoplay
      loop
      style="
        object-fit: fill;
        width: 100%;
        min-width: auto;
        height: auto;
        margin-left: 1rem;
        margin-top: 1rem;
        border-radius: 5px;
        max-height: 30rem;
      ">
    </video>
    <div>
      <p style="margin-left: 7%; font-size: 0.8rem;">IBT | How To Install</p>
    </div>
  </div>

  <div class="col-lg-3 col-sm-6 smallVideo" style="padding: 0; margin: 0;">
    <video class="border video2"
      ref="videoPlayer"
      src="./../../src/assets/intro2.mp4"
      controls
      :poster="posterUrl"
      autoplay
      loop
      style="
        object-fit: fill;
        width: 100%;
        min-width: auto;
        height: auto;
        margin-left: 1rem;
        margin-top: 1rem;
        border-radius: 5px;
        max-height: 30rem;
      ">
    </video>
    <div>
      <p style="margin-left: 7%; font-size: 0.8rem;">IBT | How To Purchase</p>
    </div>
  </div>

  <div class="col-lg-3 col-sm-6 smallVideo" style="padding: 0; margin: 0;">
    <video class="border video2"
      ref="videoPlayer"
      src="./../../src/assets/intro2.mp4"
      controls
      :poster="posterUrl"
      autoplay
      loop
      style="
        object-fit: fill;
        width: 100%;
        min-width: auto;
        height: auto;
        margin-left: 1rem;
        margin-top: 1rem;
        border-radius: 5px;
        max-height: 30rem;
      ">
    </video>
    <div>
      <p style="margin-left: 7%; font-size: 0.8rem;">IBT | How To Login</p>
    </div>
  </div>

  <div class="col-lg-3 col-sm-6 smallVideo" style="padding: 0; margin: 0;">
    <video class="border video2"
      ref="videoPlayer"
      src="./../../src/assets/intro2.mp4"
      controls
      :poster="posterUrl"
      autoplay
      loop
      style="
        object-fit: fill;
        width: 100%;
        min-width: auto;
        height: auto;
        margin-left: 1rem;
        margin-top: 1rem;
        border-radius: 5px;
        max-height: 30rem;
      ">
    </video>
    <div>
      <p style="margin-left: 7%; font-size: 0.8rem;">IBT | How To Create User</p>
    </div>
  </div>
</div>



      </div>

      <div class="col-lg-4 col-sm-12 textarea" style="margin-left: -2%;  max-width: 100%;">


        <h1 style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 48px/65px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-000000);
text-align: left;
font: normal normal bold 48px/65px Open Sans;
letter-spacing: 0.96px;
color: #000000;
text-transform: uppercase;">ELEVATE YOUR SALES PROCESS WITH</h1>
      <h1 style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 48px/65px var(--unnamed-font-family-open-sans);
text-align: left;
font: normal normal bold 48px/65px Open Sans;
letter-spacing: 0.96px;
color: #06bcf5;
text-transform: uppercase;">IBSALESFORCE</h1>
             <h1 style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 48px/65px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-000000);
text-align: left;
font: normal normal bold 48px/65px Open Sans;
letter-spacing: 0.96px;
color: #000000;
text-transform: uppercase;">THE ULTIMATE CRM SOLUTION.</h1>
 
      <P style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-25)/34px var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
text-align: left;
font: normal normal 600 25px/34px Open Sans;
letter-spacing: 0px;
color: #000000;
text-transform: uppercase; margin-top: 0%;">
 EMPOWER YOUR BUSINESS WITH IBSALESFORCE, THE ULTIMATE PLATFORM THAT BRINGS TOGETHER CENTRALIZED DATA MANAGEMENT, ENHANCED COLLABORATION, AND REAL-TIME UPDATES. EXPERIENCE THE POWER OF THIS COMPREHENSIVE SOLUTION AND TAKE YOUR BUSINESS TO THE NEXT LEVEL. DISCOVER A NEW LEVEL OF EFFICIENCY, PRODUCTIVITY, AND GROWTH WITH 
 <h6 style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 48px/65px var(--unnamed-font-family-open-sans);
text-align: left;

letter-spacing: 0.96px;
color: #06bcf5;
text-transform: uppercase;">IBSALESFORCE</h6>
</P>

</div>
    
    </div>
    <div style="margin-top: 10%;"></div>
  </div>
  <footer>
    <a href="https://www.infinitybrains.com/IbsfPrivacy" style="text-decoration: none;">  
      <h4>
            Terms & Condition | Privacy Policy
      </h4>
      </a>
      <h4>
         <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconPhone.png" style="height: 20px;"/>&nbsp; +91 7201933133
      </h4>
      <h4 style="display: flex; justify-content: space-between;">
        <a href="https://www.facebook.com/Redjinnifood/">   <img   src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/fb.png" style="height: 20px; margin-left:10px  "/> </a>
    <a href="https://www.instagram.com/redjinniindia/">   <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/insta.png" style="height: 20px; margin-left:10px  "/></a> 
      <a href="https://www.linkedin.com/company/redjinni/">  <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/linkdin.png" style="height: 20px; margin-left:10px  "/></a> 
      <a href="https://twitter.com/redjinnisurat?lang=en"> <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/tweeter.png" style="height: 20px; margin-left:10px  "/></a>
      </h4>
    </footer>
</template>
<script>
import "bootstrap/dist/css/bootstrap.css";
export default {
  data() {
    return {
      // videoUrl: "path/to/video.mp4",
      // posterUrl: "path/to/poster.jpg",
    };
  },
  methods: {
    reb(){
        // alert('https://play.google.com/store/apps/details?id=com.ib.ibsalesforce');
        // Change this to your custom URL scheme
        const appUrl = 'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce'; // Replace with the deep link for your app
      window.location.href = appUrl;
    },


  },
  mounted() {
    this.$refs.videoPlayer.play();
  },
};
</script>
<style scoped>
* {
  left: 2%;
  right: 0;
  top: 0;
  max-width: 100%;
  /* display: grid; */
}
/* .container{
  border: solid;
  width: 100%;
  height: auto;
  height: 120rem;
} */
.btnb {
  background: linear-gradient(180deg, #06bcf5 0%, #035e7b 100%);
}
label:hover {
  cursor: pointer;
}
label {
  padding-top: 1vh;
}
@media screen and (min-width: 1440px) and (max-width: 2020px) {
.video2{
  width: 5rem;
}
  /* Your CSS styles for 15-inch screen resolution go here */
}
@media screen and (min-width: 940px) {

.textarea{

    margin-top: 5rem;

}
.smallVideo{
  width: 23%;
}
.logo{
    width: 14%;
  }
  .logo2{
    width: 34%;
  }
.video1{width: 64rem;height: 100%;}
}
@media screen and (max-width: 940px) {
  .logo{
    width: 7rem;
  }
  .logo2{
    width: 14rem;
  }
  .video1{width: 100%;}
    .textarea{

        margin-top: 0rem;

    }
  .smallVideo{
    width: 92%;
  }
}
@media screen and (max-width: 948px) {
  .container{

    width: 100%;
  }

  footer{
        display: grid;
        justify-content: space-around;
        align-items: bottom;
        background-color: #0a112b;
        bottom: 0;
        margin-top:0% ;
        }
        footer h4{
        height:5vh  ;
        color:#ffff;
        display: flex;
        align-items: center;
        justify-content: center;
        }
     
  }

  @media screen and (min-width: 948px) {
  footer{
        display: flex;
        justify-content: space-around;
        align-items: bottom;
        background-color: #0a112b;
        bottom: 0;
        margin-top:0% ;
        }
        footer h4{
        height:5vh  ;
        color:#ffff;
        display: flex;
        align-items: center;
        justify-content: center;
        }
     
  }
   
      
     
</style>