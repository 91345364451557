<template>
    <meta name="referrer" content="no-referrer-when-downgrade">
      <div class="main-container">
        <div class="container" style="background-color: #ffff; border-radius: 14px; width: 910px;">
          <div class="row" style="">
            <div class="col-lg-12 col-md-12 col-sm-12" style="padding-right: 30px;padding-left: 30px;">
              <div class="inner-container" >
                <h2>Fill The Details</h2>
               
                <p>Enter Your Company Details To Use <span v-if="freeTrial" style="margin-top:-2% ; font-weight: 700; color:rgba(230,137,129,255);">15 Days Free Trials</span></p>
  
                <p style="font-size: 1rem; color:rgba(230,137,129,255);"  :class="messageClass">{{ formError }}</p>
              </div>
              <div style="border: 2px #464545 dashed; margin-top: 4%; margin-bottom: 3%;"></div>
  
              <div class="form">
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="mb-3" v-if="!freeTrial">
                        <label for="email">Email Address</label>
                        <div class="input-group">
                          <input type="email" id="email" class="form-control" :disabled="emailDisabled" v-model="email" :class="[responseStatus === 'success' ? 'success-border' : (responseStatus === 'error' ? 'error-border shake' : '')]">
                          <div class="input-group-append">
                            <button class="btn btn-primary" style="" v-if="virify" type="button" @click="verifyEmail" :disabled="loading">
                              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span v-else>CHECK</span>
                            </button>
                            <button class="btn btn-secondary" type="button" @click="resetForm" v-if="emailDisabled && responseStatus === 'success'">
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
  
                      </div>
                      <div class="mb-3" v-else>
                        <label for="email">Email Address</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <input type="email" id="email" class="form-control" :disabled="emailDisabled" v-model="email" :class="[responseStatus === 'success' ? 'success-border' : (responseStatus === 'error' ? 'error-border shake' : '')]">
                            <div class="custom-button-wrapper">
                              <button class="btn btn-primary custom-button" v-if="virify" type="button" @click="verifyEmail" :disabled="loading">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-else>CHECK</span>
                              </button>
                              <button class="btn btn-secondary custom-button" type="button" @click="resetForm" v-if="emailDisabled && responseStatus === 'success'">
                            <i class="fas fa-times"></i>
                          </button>
                            </div>
                          </div>
                        
                        </div>
                      </div>
  
  
  
                    <div class="mb-3">
                      <label for="companyName">Company Name</label>
                      <input type="text" id="companyName" class="form-control" v-model="companyName" />
                    </div>
                    <div class="mb-3">
                      <div class="row" v-if="!freeTrial">
                          <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                            <label for="firstName">First Name</label>
                            <input type="text" id="firstName" class="form-control" v-model="firstName">
                          </div>
                          <div class="col-lg-6 col-sm-12 col-md-12 mb-0">
                            <label for="lastName">Last Name</label>
                            <input type="text" id="lastName" class="form-control" v-model="lastName">
                          </div>
                        </div>
                        <div v-else class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <label for="firstName">First Name</label>
                            <input type="text" id="firstName" class="form-control" v-model="firstName">
                          </div>
                          <div class="col-lg-12 col-sm-12 col-md-12 mb-0">
                            <label for="lastName">Last Name</label>
                            <input type="text" id="lastName" class="form-control" v-model="lastName">
                          </div>
                        </div>
                    </div>
                    <div class="mb-3">
                      <label for="contactNumber">Contact Number</label>
                      <input type="text" id="contactNumber" class="form-control" v-model="contactNumber">
                    </div>
  
                    <div class="mb-3"  v-if="freeTrial">
  
  
  
  
  
          <label for="password">Password</label>
          <div class="input-group">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              class="form-control"
              v-model="createPassword"
              required
            />
            <div class="input-group-append" style="background-color: transparent;" >
              <span class="input-group-text" style="height: 100%;"  >
                <i
                  :class="[showPassword ? 'fas fa-eye' : 'fas fa-eye-slash']"
                  @click="togglePasswordVisibility"
                  style="cursor: pointer; color: gray;"
                ></i>
              </span>
            </div>
          </div>
        </div>
  
  
                    <!-- <div class="mb-3">
                    <label for="couponCode">Apply Coupon Code</label>
                    <div class="input-group">
                      <input type="text" id="couponCode" class="form-control" v-model="couponCode">
                      <div class="input-group-append">
                        <button class="btn btn-primary" type="button" @click="applyCoupon">Apply</button>
                      </div>
                    </div>
                  </div> -->
  
                  <div class="mb-3" v-if="!freeTrial">
                    <label for="companyAddress">Company Address</label>
                    <input type="text" id="companyAddress" class="form-control" v-model="companyAddress">
                  </div>
  
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
  
                  
                  <div class="mb-3" v-if="freeTrial">
                <label for="LogoGet">Company Logo</label>
                <input type="file" id="LogoGet" class="form-control custom-file-input" accept="image/*" ref="fileInput" @change="handleFileUpload1">
              </div>
              <div class="mb-3" v-if="freeTrial">
                    <label for="companyAddress">Company Address</label>
                    <input type="text" id="companyAddress" class="form-control" v-model="companyAddress">
                  </div> 
                    <div class="mb-3">
                      <div class="row">
                        <div style="display: none;" class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <label style="display: none;" for="country">Country</label>
                      <div class="select-wrapper">
                        <select id="country" class="form-control" v-model="country_id"  @change="getState()" required>
                          <option value="">Select Country</option>
                          <!-- Add options for countries dynamically -->
                          <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                        </select>
                        <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png"  alt="Dropdown Arrow">
                      </div>
                    </div>
  
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <label for="state">State</label>
                  <select id="state" requires class="form-control" v-model="state_id" @change="getCity()" required>
                    <option value="">Select State</option>
                    <!-- Add options for states dynamically based on selected country -->
                    <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                  </select>
                  <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png" style="" class="arrow-image2" alt="Dropdown Arrow"/>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                  <label for="city">City</label>
                  <select id="city" requires class="form-control" v-model="city_id">
                    <option value="">Select City</option>
                    <!-- Add options for cities dynamically based on selected state -->
                    <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
                  </select>
                  <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png" class="arrow-image1"  alt="Dropdown Arrow"/>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <label for="pincode">Pincode</label>
                  <input type="text" id="pincode" class="form-control" v-model="pincode">
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <label for="salesManager">Sales Manager Code</label>
                      <input type="text" id="salesManager" class="form-control" v-model="salesManager">
                    </div>
  
                    <div v-if="freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <label for="gst">Enter Gst Number</label>
                      <input type="text" id="gst" class="form-control" v-model="gst">
                    </div>
                    <div v-if="!freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <label for="couponCode">Apply Coupon Code</label>
                      <div class="input-group">
                        <input type="text" id="couponCode" class="form-control" v-model="couponCode">
                        <div class="input-group-append">
                        <button class="btn btn-primary" type="button" @click="applyCoupon">Apply</button>
                      </div>
                    </div>
  
                  </div>
                  <div v-if="!freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <label for="amount">Amount</label>
                        <input type="text" id="amount" class="form-control" v-model="amount" disabled>
                      </div>
              </div>
            </div>
  
  
                  </div>
                </div>
                <div class="text-center input-group-append" v-if="!freeTrial" style="width: 100%;">
                  <button class="btn btn-primary" @click="PayNow()"  style="width: 100%; font-weight: 700;" >PAY NOW</button>
                </div>
                <div class="text-center input-group-append" v-else style="width: 100%;">
                  <!-- <button class="btn btn-primary" id="saveAs"  @click="Save()"  style="width: 100%; font-weight: 700;" >Save</button> -->
                  <button class="btn btn-primary" id="saveAs"  @click="Save()" :disabled="buttonDisabled" style="width: 100%; font-weight: 700;">SUBMIT</button>
  
                </div>
  
  
  
              </div>
              <p style="display: flex; align-items: center; justify-content: center;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 16px var(--unnamed-font-family-open-sans);
  text-align: center;
  font: normal normal 600 16px Open Sans;
  letter-spacing: 0.4px;
  opacity: 1;">If Already Purchased plan ? <a @click="login()" style="color:#0AAFE3; cursor: pointer; font-weight: 700;">&nbsp; click here</a></p>
            </div>
            <!-- <div class="col-lg-5 col-sm-12 col-md-12" :style="containerStyle">
        <div class="inner-container">
          <div class="inner-container">
            <h2>Select Plan</h2>
            <p>Start your free trial today and see the difference our product can make.</p>
          </div>
          <div class="row d-flex justify-content-center" style="margin-top: 30%;">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 plan-item " @click="selectPlan('plan1')" >
  
              <div class="form-check">
                <input class="form-check-input" type="radio" name="plan" id="plan1" value="plan1" checked  v-model="selectedPlan"><p>FREE TRIAL</p><h6 style="float: right; font-size: 1.5rem; font-weight: 700; margin-top: 2.5%;">15 Days <br/> <h6 style="color:rgb(83, 126, 171);  font-size: 1rem; margin-top: 0.8%;  font-weight: 700;">Free Trial</h6></h6>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 plan-item" @click="selectPlan('plan2')">
  
              <div class="form-check">
                <input class="form-check-input" type="radio" name="plan" id="plan2" value="plan2"  v-model="selectedPlan"><p>STANDARD</p><h6 style="float: right; font-size: 1.5rem;  font-weight: 700; margin-top: 2.5%;">15 Days <br/> <h6 style="color:rgb(83, 126, 171);  font-size: 1rem; margin-top: 0.8%;  font-weight: 700;">Free Trial</h6></h6>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 plan-item" @click="selectPlan('plan3')">
  
              <div class="form-check">
                <input class="form-check-input" type="radio" name="plan" id="plan3" value="plan3" ><p>PREMIUM</p><h6 style="float: right; font-size: 1.5rem; font-weight: 700; margin-top: 2.5%;">15 Days <br/> <h6 style="color:rgb(83, 126, 171);  font-size: 1rem; margin-top: 0.8%;  font-weight: 700;">Free Trial</h6></h6>
              </div>
            </div>
          </div>
        </div>
      </div> -->
  
  
  
          </div>
        </div>
  
      </div>
      <footer>
      <a href="https://www.infinitybrains.com/IbsfPrivacy" style="text-decoration: none;">
        <h4>
              Terms & Condition | Privacy Policy
        </h4>
        </a>
        <h4>
           <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconPhone.png" style="height: 18px;"/>&nbsp; +91 7201933133
        </h4>
        <h4 style="display: flex; justify-content: space-between;">
          <a href="https://www.facebook.com/Redjinnifood/">   <img   src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/fb.png" style="height: 20px; margin-left:10px  "/> </a>
      <a href="https://www.instagram.com/redjinniindia/">   <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/insta.png" style="height: 20px; margin-left:10px  "/></a>
        <a href="https://www.linkedin.com/company/redjinni/">  <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/linkdin.png" style="height: 20px; margin-left:10px  "/></a>
        <a href="https://twitter.com/redjinnisurat?lang=en"> <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/tweeter.png" style="height: 20px; margin-left:10px  "/></a>
        </h4>
      </footer>
    </template>
  
    <script>
    import 'typeface-open-sans';
    import axios from 'axios';
    export default {
      data() {
        return {
          firstName: '',
          lastName: '',
          email: '',
          contactNumber: '',
          companyName:'',
          couponCode: '',
          companyAddress: '',
          country: '',
          state: '',
          city: '',
          salesManager: '',
          gst: '',
          createPassword: '',
          formError: '',
          messageClass: '',
          pincode:'',
          // loading: false,
        responseStatus: null,
        emailDisabled: false,
          amount:"1",
          showPassword: false,
          selectedPlan: null,
          buttonDisabled:true,
          freeTrial: false,
          loading: false,
          emailDisable:false,
          country_id:'101',
          state_id:'',
          city_id:'',
          countries:[],
          states:[],
          cities:[],
          virify: true,
          payuUrl: "https://secure.payu.in/_payment",
          mkey: "nxpvv9VZ",
          saltKey: "3oFxUMtWG2",
          surl: window.location.origin + "/success",
          furl: window.location.origin + "/",
          txnid: this.makeid(),
  
        };
      }, computed: {
        containerStyle() {
          return {
            backgroundColor: '#0AAFE3',
            color: '#ffff',
            borderRadius: '0 14px 14px 0',
            paddingRight: '50px',
            paddingLeft: '50px',
            height: this.selectedPlan ? '500px' : 'auto',
            transition: 'height 0.3s ease-in-out',
          };
        },
      },
      mounted(){
        this.getData();
        this.makeid();
        this.freeTrial = true;
          // this.yourmessage = 'helo'
        const planItems = document.querySelectorAll('.plan-item');
    planItems.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('show');
      }, index * 200); // Adjust the delay between each plan item
    });
      // this.selectPlan('plan1');
      },
      methods: {
        handleFileUpload1() {
         const fileInput = this.$refs.fileInput;
  
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      this.selectedImage = file;
      this.selectedFileName = file.name;
      console.log(this.selectedImage);
    } else {
      this.selectedImage = null;
      this.selectedFileName = '';
    }
      },
        PayNow(){
  
          var data =
            this.mkey +
            "|" +
            this.txnid +
            "|" +
            this.amount +
            "|" +
            this.productinfo +
            "|" +
            this.FirstName +
            "|" +
            this.EmailAddress +
            "|||||||||||";
          var sha512 = require("js-sha512");
          var salt = "3oFxUMtWG2";
          var hash = sha512(data + salt);
  
          console.log(hash);
          console.log(data);
          document.getElementById("hash").value = hash;
          document.getElementById("paymentForm").submit();
  
  
        },
        makeid() {
          var text = "";
          var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          for (var i = 0; i < 20; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
  
          return text;
        },
        getData() {
        axios.get("https://api.restroworld.com/api/countries?is_light=true")
          .then((result) => {
            this.countries = result.data.data;
  
            if (this.country_id) {
              this.getState();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
      getState() {
        axios.get("https://api.restroworld.com/api/states_of_country?is_light=1", {
            params: {
              country_id: this.country_id,
            },
          })
          .then((result) => {
            this.states = result.data.data;
            this.state_id = ''; // Reset the selected state
            this.getCity();
          })
          .catch((error) => {
            console.error(error);
          });
      },
      getCity() {
        axios.get("https://api.restroworld.com/api/cities_of_state?is_light=true", {
            params: {
              state_id: this.state_id,
            },
          })
          .then((result) => {
            this.cities = result.data.data;
            this.city_id = ''; // Reset the selected city
          })
          .catch((error) => {
            console.error(error);
          });
  
        },
        Save(){

          if (!this.cities) {
  this.formError = "Please select a city.";
  this.messageClass = 'danger';
} else if (!this.city_id || !this.state_id) {
  this.formError = "Please select both a state and a city.";
  this.messageClass = 'danger';
} else {
          const formData = new FormData();
      formData.append('companylogo', this.selectedImage);
  if (this.firstName) {
    formData.append('first_name', this.firstName);
  }
  
  if (this.lastName) {
    formData.append('last_name', this.lastName);
  }
  
  if (this.email) {
    formData.append('email', this.email);
  }
  
  if (this.contactNumber) {
    formData.append('mobile_no', this.contactNumber);
  }
  
  if (this.createPassword) {
    formData.append('password', this.createPassword);
  }
  
  if (this.companyAddress) {
    formData.append('company_address', this.companyAddress);
  }
  
  if (this.gst) {
    formData.append('gst_number', this.gst);
  }
  
  if (this.companyName) {
    formData.append('company_name', this.companyName);
  }
  
  if (this.country_id) {
    formData.append('country', this.country_id);
  }
  
  if (this.state_id) {
    formData.append('state', this.state_id);
  }
  
  if (this.city_id) {
    formData.append('city', this.city_id);
  }
  
  if (this.Pincode) {
    formData.append('pincode', this.Pincode);
  }
  
  if (this.salesManager) {
    formData.append('sales_manager_name', this.salesManager);
    


    
  }
  formData.append('is_freetrial', "1");
          axios.post('https://uatbackendsales.infinitybrains.com/public/api/store-vendor',formData).then((response) => {
              this.formError = response.data.message;
              this.messageClass = 'success';
              const data = {
          name: 'John',
          age: 30,
        };
        const queryParameters = new URLSearchParams(data).toString();
        const deepLinkUrl = `ibsalesforce://open?${queryParameters}`;
  
        // Try to open the deep link URL
        window.location.href = deepLinkUrl;
  
        // Redirect to the Play Store if the deep link fails
        setTimeout(() => {
          const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce';
          window.location.href = playStoreUrl;
        }, 3000); // Change the timeout value as needed
              this.firstName =  '',
          this.lastName = '',
          this.email = '',
          this.contactNumber = '',
          this.companyName ='',
          this.couponCode =  '',
          this.companyAddress = '',
          // this.country: '',
          // state: '',
          // city: '',
          this.salesManager = '',
          this.gst = '',
          this.createPassword = '',
          // this.formError = '',
          // this.messageClass: '',
          this.pincode='';
          // const isAppInstalled = this.isAppInstalledOnDevice('com.ib.ibhrms');
  
          //         if (isAppInstalled) {
          //           this.openAppOnDevice('com.ib.ibhrms');
          //         } else {
          //           this.openPlayStore('com.ib.ibhrms');
          //         }
  
            })
            .catch((error) => {
              this.formError = error.response.data.message;
              this.messageClass = 'danger';
            });

          }
        },
  
        resetForm() {
        this.email = ''; // Reset the email input
        if(this.responseStatus == 'success' ){
          this.virify = true;
        }
        this.emailDisabled = false; // Disable the input
        this.buttonDisabled = true; // Disable the save button
      },
        verifyEmail() {
          this.loading = true; // Show the spinner
  
    axios.post('https://uatbackendsales.infinitybrains.com/public/api/verifyEmail', { email: this.email })
      .then(response => {
        // Verification successful
        console.log(response.data); // Do something with the response data
        this.loading = false;
        if(response.data.message === 'Record has been shown successfully.'){
            // document.getElementById('');
            this.responseStatus = 'error'
            this.buttonDisabled = true;
            this.virify = true;
            this.firstName = response.data.user.first_name;
            this.lastName= response.data.user.last_name;
          // this.email:,
          this.contactNumber = response.data.user.phone_no;
          this.companyName = response.data.user.companyName;
          // this.couponCode: '',
          this.companyAddress =  response.data.user.address;
          // createPassword: '',
        }else{
          this.responseStatus = 'success';
          this.buttonDisabled = false;
          this.emailDisabled = true;
          this.virify = false;
        }
      })
      .catch(error => {
        // Verification failed
        this.formError = error.response.data.message; // Handle the error
        this.responseStatus = 'error';
        this.loading = false;
        setTimeout(() => {
          this.responseStatus = null;
        }, 1000);
      });
        },
        selectPlan(plan) {
          this.selectedPlan = plan;
        },
        submitForm() {
          // Handle form submission logic here
        },
        togglePasswordVisibility() {
          this.showPassword = !this.showPassword;
        },
        login(){
  
            const data = {
          name: 'John',
          age: 30,
        };
        const queryParameters = new URLSearchParams(data).toString();
        const deepLinkUrl = `ibsalesforce://open?${queryParameters}`;
  
        // Try to open the deep link URL
        window.location.href = deepLinkUrl;
  
        // Redirect to the Play Store if the deep link fails
        setTimeout(() => {
          const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce';
          window.location.href = playStoreUrl;
        }, 3000); // Change the timeout value as needed
        },
        selectPlan(planId) {
        document.getElementById(planId).checked = true;
        // alert(planId);
          if(planId == 'plan1')
          {
  
              this.freeTrial = true;
  
          }else{
            this.freeTrial = false;
          }
      }
      },
    };
    </script>
  
    <style scoped>
    .custom-input-group {
    display: flex;
    flex-direction: row;
    border: 1px solid grey;
    padding: 1px;
  }
  
  .custom-input {
    flex-grow: 2;
    border: none;
  }
  
  .custom-input:focus {
    outline: none;
  }
  
  .custom-input-group:focus-within {
    outline: 1px solid #0AAFE3;
  }
  
  .btn {
    border: 1px solid #0AAFE3;
    background: #0AAFE3;
    color: white;
  }
    .container{
      width: 50%;
    }
    .main-container {
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      font-family: 'Open Sans', sans-serif;
      color: #2c2c2c ;
      /* max-height: 100%; */
      /* Other styles for the main container */
    }
    .selected {
      transform: scale(1.1); /* Increase the size by 10% */
      transition: transform 0.3s ease; /* Add a smooth transition effect */
    }
    label{
      font:normal normal 600 0.79rem Open Sans;
      color: #404040;
      margin-bottom: 1%;
    }
  
    .input-group-append .btn, .input-group-append .btn::after , .input-group-append .btn::before , .input-group-append .btn:hover ,.input-group-append .btn:active {
  
      background-color: #0AAFE3;
      border: #0AAFE3;
  
  
    }
    .inner-container {
  
      text-align: center;
      margin-top: 6%;
      /* Styles for the inner container */
    }
  
    .inner-container h2 {
      font-weight: 700;
      font-size: 2.5rem;
    }
    .plan-item {
        border-radius: 5px;
        border: transparent;
        height: 70px;
        background-color: #ffff;
        color: black;
      }
  
      .plan-item p {
        font-size: 1.2rem;
        float: left;
        margin-top: 4.5%;
        padding-left: 10px;
      }
    .inner-container p {
      font-weight: 700;
      font-size: 1.5rem;
    }
    .form {
      margin-top: 2%;
      margin-bottom: 2%;
  
    }
  
    .input-group {
    display: flex;
  }
  
  .input-group-prepend {
    position: relative;
    flex: 1;
  }
  
  .form-control {
    border-radius: 8px;
    border-right: none;
    margin-right: 0.5%;
  }
  
  .custom-file-input {
    position: relative;
    overflow: hidden;
  }
  
  .custom-file-input::after {
    content: 'browse';
    width: 10vh;
      float: right;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0AAFE3;
      padding: 1px;
      color: #ffffff;
      margin: 0 auto;
      cursor: pointer;
      border-radius:8px;
      transition: background-color 0.3s ease;
  }
  
  .custom-file-input:hover::after {
    background-color: #426c9e;
  }
  
  .custom-file-input:focus::after {
    outline: none;
    background-color: #b9b9b9;
  }
  
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
    width: 0;
    height: 0;
  
  }
  
  .custom-file-input::before {
    content: attr(placeholder);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0.5em;
    background-color: transparent;
    color: #999999;
    pointer-events: none;
    transition: color 0.3s ease;
  }
  
  .custom-file-input:focus::before,
  .custom-file-input:not(:placeholder-shown)::before {
    color: #ffffff;
  }
  
  .custom-file-input::file-selector-button {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .custom-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  
    padding-right: 4px;
  }
  
  .custom-button {
    border-radius: 8px;
    border: none;
    height: 100%;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0AAFE3;
    color: white;
  }
  
  .custom-button .spinner-border {
    margin-right: 5px;
    padding: 0;
  
  }
  .spinner-border{
    padding-right: 0;
  }
  .fa-times {
    font-size: 1rem;
  }
  
  .success-border {
    border-color: green;
  }
  
  .error-border {
    border-color: red;
  }
  
  .shake {
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-5px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(5px);
    }
  }
  
  .spinner-border {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
    border: 0.2em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  
  
    select{
  
      background-color: #f5f5f5;
      /* Add any other custom styles for input[type="text"] */
  
    }
  
    input {
      background-color: #f5f5f5;
      border:1px solid #C8C8C8;
      margin-right:100px ;
      /* Add any other custom styles for input[type="text"] */
    }
  
    input:focus, textarea:focus, select:focus{
            outline: none;
        }
        textarea:focus,
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="datetime"]:focus,
    input[type="datetime-local"]:focus,
    input[type="date"]:focus,
    input[type="month"]:focus,
    input[type="time"]:focus,
    input[type="week"]:focus,
    input[type="number"]:focus,
    input[type="email"]:focus,
    input[type="url"]:focus,
    input[type="search"]:focus,
    input[type="tel"]:focus,
    input[type="color"]:focus,
    select:focus,
    .uneditable-input:focus {
      border-color: #f5f5f5;
      box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 8px #b9b9b9;
      margin-top: 0.5%;
      outline: 0 none;
      background-color: #f5f5f5;
    }
    
  
  
    /* .form-check-input ,.form-check-input:checked::after,.form-check-input::before, .form-check-input.active .form-check-input:focus  {
      margin-top: 6%;
      border: solid black;
      font-size: 1.2rem;
      outline: none;
  
    } */
    .form-check-input {
        /* Add your custom styles here */
        /* Example styles */
        font-size: 1.2rem;
        margin-top: 6%;
        border: 2px solid #000;
        border-radius: 50%;
        background-color: #fff;
      }
  
      .form-check-input:checked {
        /* Add custom styles for the checked state */
        /* Example styles */
        background-color: #0AAFE3;
      }
  
      .form-check-input:focus {
        /* Add custom styles for the focused state */
        /* Example styles */
        outline: none;
        box-shadow: 0 0 0 4px #f5f5f5;
      }
    ul li
    {
      text-decoration: none;
    }
    .plan-item {
      opacity: 0;
      transform: translateY(100px);
      transition: opacity 0.5s, transform 0.5s;
    }
  
    .plan-item.show {
      opacity: 1;
      transform: translateY(0);
    }
    footer{
      font-size: 0.5rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      /* justify-content: center; */
      background-color: #0a112b;
    }
    footer h4{
      height:5vh  ;
      color:#ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
    }
    .success-border {
      border-color: rgb(68, 216, 68);
    }
    .shake {
      animation: shake 0.5s;
    }
  
    @keyframes shake {
      0% { transform: translateX(0); }
      25% { transform: translateX(-5px); }
      75% { transform: translateX(5px); }
      100% { transform: translateX(0); }
    }
    .hidden {
      display: none;
    }
    .error-border {
      border-color: red;
    }
    .success {
      color: rgb(59, 201, 59);
    }
  
    .danger {
      color: rgb(236, 120, 120);
    }
  
    @media screen and (min-width: 940px) {
     .container{
      width:50%
     }
     .arrow-image2 {
        float: right;
        margin-top: -6%;
        margin-right: 10px;
    }
    .arrow-image1 {
        float: right;
        margin-top: -12%;
        margin-right: 10px;
    }
  
  
    }
  
    @media screen and (max-width: 940px) {
     .container{
      width:100%
     }
  }
  @media screen and (max-width: 948px) {
    footer{
          display: grid;
          justify-content: space-around;
          align-items: bottom;
          background-color: #0a112b;
          bottom: 0;
          margin-top:0% ;
          }
          footer h4{
          height:5vh  ;
          color:#ffff;
          display: flex;
          align-items: center;
          justify-content: center;
          }
  
    
          .arrow-image2 {
        float: right;
        margin-top: -7%;
        margin-right: 10px;
    }
    
    .arrow-image1 {
        float: right;
        margin-top: -7%;
        margin-right: 10px;
    }
  
  }
  
  
  
  
  
  
    </style>
  