<template>
  <div class="d-flex flex-column min-vh-100">
    <div
      class="flex-grow-1 d-flex align-items-center justify-content-center flex-column"
    >
      <div class="container rounded shadow p-3 bg-body w-50 rounded-3">
        <div class="inner-container d-flex flex-column">
          <h2
            class="text-decoration-underline"
            style="font-size: 1.5rem; font-weight: 600"
          >
            Bill Details
          </h2>
        </div>

        <div class="row" style="font-weight: 600">
          <div class="col">
            <span>Subscripation Price</span>
          </div>
          <div class="col text-end">
            <span>&#x20B9;</span>
            <span>{{ amount }}</span>
            <!-- <span>{{ paymentDetails?.total_amount || '' }}</span> -->
          </div>
        </div>

        <hr />
        <div class="row" style="font-weight: 600">
          <div class="col">
            <span>C-GST(9%)</span>
          </div>
          <div class="col text-end">
            <span>&#x20B9;</span>
            <span>{{ cgst }}</span>
          </div>
        </div>

        <hr />
        <div class="row" style="font-weight: 600">
          <div class="col">
            <span>S-GST(9%)</span>
          </div>
          <div class="col text-end">
            <span>&#x20B9;</span>
            <span>{{ sgst }}</span>
          </div>
        </div>

        <hr />
        <div class="row" style="font-weight: 600">
          <div class="col">
            <span>Payable Amount</span>
          </div>
          <div class="col text-end">
            <span>&#x20B9;</span>
            <span>{{ total_amount }}</span>
          </div>
        </div>
      </div>

      <!-- Next Button (Visible only after email verification) -->
      <div class="text-center my-3 w-50 custom-next-btn">
        <button class="btn btn-primary w-100" @click="payNow">PAY NOW</button>
      </div>

      <!-- PayU form -->
      <!-- <form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl"> -->
      <form
        method="POST"
        class="pl-5 pr-5"
        id="paymentForm"
        :action="payuUrl"
        @submit.prevent="generateHashAndSubmitForm"
      >
        <input type="hidden" name="key" v-model="mkey" size="64" />
        <input type="hidden" name="txnid" v-model="txnid" size="64" />

        <!-- <input type="hidden" name="amount" v-model="amount_pay" size="64" /> -->
        <input type="hidden" name="amount" v-model="total_amount" size="64" />

        <input
          type="hidden"
          name="productinfo"
          v-model="productInfo"
          size="64"
        />

        <input type="hidden" name="firstname" v-model="first_name" size="64" />
        <input
          type="hidden"
          name="service_provider"
          value="payu_paisa"
          size="64"
        />
        <input type="hidden" name="email" v-model="email" size="64" />

        <input type="hidden" name="phone" v-model="mobile_no" size="64" />

        <input type="hidden" name="lastname" v-model="last_name" size="64" />
        <input type="hidden" name="surl" v-model="surl" />
        <input type="hidden" name="furl" v-model="furl" />
        <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
      </form>
    </div>
    <!-- Include Footer component -->
    <Footer />
  </div>
</template>

<script>
// import axios from "axios";
import Footer from "@/components/Footer.vue";
import sha512 from "js-sha512";

export default {
  name: "LogoPaymentPage",
  components: {
    Footer,
  },
  data() {
    return {
      cgst: "",
      sgst: "",
      amount: "",
      total_amount: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      uIdUpdPay: "",

      email: "",
      emailVerified: false,
      message: "",
      // amount_pay: '678.64',
      hash: "",
      txnid: "",
      productInfo: "HRMS Logo Payment",
      payuUrl: "https://secure.payu.in/_payment",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      // surl: 'https://app.ibdelight.in/success',
      surl: window.location.origin + "/success",
      // furl: 'https://app.ibdelight.in/',
      furl: window.location.origin + "/",
      // furl: 'https://ibhrms.com/',
    };
  },

  computed: {
    paymentDetails() {
      return this.$store.state.paymentDetails || {};
    },
  },

  created() {
    // Fetch the payment details from the Vuex store
    const paymentDetails = this.$store.state.paymentDetails;
    console.log("paymentDetails:", paymentDetails);

    this.total_amount = paymentDetails.total_amount || "";
    this.cgst = paymentDetails.cgst || "";
    this.sgst = paymentDetails.sgst || "";
    this.amount = paymentDetails.amount || "";
    this.first_name = paymentDetails.first_name || "";
    this.last_name = paymentDetails.last_name || "";
    this.mobile_no = paymentDetails.mobile_no || "";
    this.email = paymentDetails.user_email || "";
    this.uIdUpdPay = paymentDetails.user_update_payment_status || "";
  },

  mounted() {
    this.makeid();
  },

  methods: {
    payNow() {
      this.emailVerified = true;
      if (this.emailVerified) {
        this.generateHashAndSubmitForm();
      }
    },

    generateHashAndSubmitForm() {
      const data =
        this.mkey +
        "|" +
        this.txnid +
        "|" +
        // this.amount_pay +
        this.total_amount +
        "|" +
        this.productInfo +
        "|" +
        this.first_name +
        "|" +
        this.email +
        "|||||||||||";

      this.hash = sha512(data + this.saltKey);

      if (this.hash) {
        localStorage.setItem("hash", this.hash);
        localStorage.setItem("expireSession", "sesion12dgtdb");
      }

      console.log(this.hash);
      console.log(data);

      document.getElementById("hash").value = this.hash;

      const paymentData = {
        hashKey: this.hash,
        UserId: this.uIdUpdPay,
      };

      localStorage.setItem("paymentLogoPlan", JSON.stringify(paymentData));

      document.getElementById("paymentForm").submit();
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      this.txnid = text;
    },
  },
};
</script>

<style scoped>
/* Add additional styles as needed for responsiveness */

@media (max-width: 620px) {
  .container {
    width: 75% !important;
  }

  .custom-next-btn {
    width: 75% !important;
  }
}

@media (max-width: 420px) {
  .container {
    width: 100% !important;
  }

  .custom-next-btn {
    width: 100% !important;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 35% !important;
  }

  .custom-next-btn {
    width: 35% !important;
  }
}
</style>
