<template>
  <div class="maincontainer" id="maincontainer">
    <div class="container" style>
      <div class="successCoupon" v-if="status === 0">
        <!-- <img src="./../../src/assets/iconS.png" style="height: 29px;" /> -->
        <span class="SCA1">₹{{this.formatNumber(this.PaymentDetails.discount)}}/-&nbsp;saved!</span>
        <span class="SCA2">with&nbsp;the&nbsp;applied&nbsp;coupon</span>
      </div>
      <!-- <div class="successCoupon">
        <img src="./../../src/ASSET/iconS.png" style="height: 25px;" />
        <span class="SCA1">Congratulations...!! Your subscription validity has been extended to 1 year (9 months free).</span>
        <span class="SCA2">with&nbsp;the&nbsp;applied&nbsp;coupon</span>
      </div>-->

      <div class="col-lg-8 col-md-12 col-sm-12 col-12 left-column" id="left-column1" style="padding: 5%;padding-bottom: 1%;margin-bottom: 5%;">
        <div class="inner-container">
          <h2>Fill The Details</h2>
          <p style="margin-top:-2% ;">Enter Your Company Details to Purchase the Product.</p>
          <p
            v-if="freeTrial"
            style="margin-top:-2% ; font-weight: 700; color:rgba(230,137,129,255);"
          >15 Days Free Trials</p>
          <p style="font-size: 1rem;" :class="messageClass">{{ formError }}</p>
        </div>
        <div style="border: 2px #464545 dashed; margin-top: 4%; margin-bottom: 6%;"></div>
        <div class="form">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="mb-3">
                <label for="email">Email Address</label>

                <div class="input-group">
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    :disabled="emailDisabled"
                    v-model="email"
                  />
                   </div>
              </div>

              <div class="mb-3">
                <label for="companyName">Company Name</label>
                <input type="text" id="companyName" class="form-control" v-model="companyName" />
              </div>
              <div class="mb-3">
                <div class="row" v-if="!freeTrial">
                  <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" class="form-control" v-model="firstName" />
                  </div>
                  <div class="col-lg-12 col-sm-12 col-md-12 mb-0">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" class="form-control" v-model="lastName" />
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" class="form-control" v-model="firstName" />
                  </div>
                  <div class="col-lg-12 col-sm-12 col-md-12 mb-0">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" class="form-control" v-model="lastName" />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="contactNumber">Mobile Number</label>
                <input type="text" id="contactNumber" class="form-control" v-model="contactNumber" />
              </div>

              <div class="mb-3" v-if="!freeTrial">
                <label for="password">Create Password</label>
                <div class="input-group">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    id="password"
                    class="form-control"
                    v-model="createPassword"
                    required
                  />
                  <div class="input-group-append" style="background-color: transparent;">
                    <span class="input-group-text" style="height: 100%;">
                      <i
                        :class="[showPassword ? 'fas fa-eye' : 'fas fa-eye-slash']"
                        @click="togglePasswordVisibility"
                        style="cursor: pointer; color: gray;"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="freeTrial">
                <label for="companyAddress">Company Address</label>
                <input
                  type="text"
                  id="companyAddress"
                  class="form-control"
                  v-model="companyAddress"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="mb-3" v-if="!freeTrial">
                <label for="companyAddress">Company Address</label>
                <input
                  type="text"
                  id="companyAddress"
                  class="form-control"
                  v-model="companyAddress"
                />
              </div>
              <div class="mb-3" v-if="!freeTrial">
                <label for="LogoGet">Company Logo</label>
                <input
                  type="file"
                  id="LogoGet"
                  class="form-control custom-file-input"
                  accept="image/*"
                  ref="fileInput"
                  @change="handleFileUpload1"
                />
              </div>

              <div class="mb-3" v-if="freeTrial">
                <label for="password">Password</label>
                <div class="input-group">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    id="password"
                    class="form-control"
                    v-model="createPassword"
                    required
                  />
                  <div class="input-group-append" style="background-color: transparent;">
                    <span class="input-group-text" style="height: 100%;">
                      <i
                        :class="[showPassword ? 'fas fa-eye' : 'fas fa-eye-slash']"
                        @click="togglePasswordVisibility"
                        style="cursor: pointer; color: gray;"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="row">
                  <!-- <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <label for="country">Country</label>
                  <div class="select-wrapper">
                    <select id="country" class="form-control" v-model="country_id"  @change="getState()" required>
                      <option value="">Select Country</option>

                      <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                    </select>
                    <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png" class="arrow-image" alt="Dropdown Arrow">
                  </div>
                  </div>-->

                  <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label for="state">State</label>
                    <select
                      id="state"
                      class="form-control"
                      v-model="state_id"
                      @change="getCity()"
                      required
                    >
                      <option value>Select State</option>

                      <option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                      >{{ state.name }}</option>
                    </select>
                    <img
                      src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png"
                      class="arrow-image2"
                      alt="Dropdown Arrow"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                    <label for="city">City</label>
                    <select id="city" class="form-control" v-model="city_id">
                      <option value>Select City</option>

                      <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
                    </select>
                    <img
                      src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png"
                      class="arrow-image1"
                      alt="Dropdown Arrow"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                    <label for="pincode">Pincode</label>
                    <input type="text" id="pincode" class="form-control" v-model="pincode" />
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label for="salesManager">Sales Manager Code</label>
                    <input
                      type="text"
                      id="salesManager"
                      class="form-control"
                      v-model="salesManager"
                    />
                  </div>

                  <div v-if="!freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label for="gst">Gst Number</label>
                    <input type="text" id="gst" class="form-control" v-model="gst" />
                  </div>
                  <!-- <div v-if="!freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <label for="couponCode">Apply Coupon Code</label>
                  <div class="input-group" :class="{ 'success-border': apiSuccess,'error-border': apiError }">
                      <input type="text" id="couponCode" class="form-control" :class="getClassNames2" v-model="couponCode">
                      <div class="input-group-append">
                        <button class="btn btn-primary" type="button" @click="applyCoupon">Apply</button>
                      </div>
                    </div>


              </div>
              <div v-if="!freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label for="amount">Amount</label>
                    <input  type="text" id="amount" class="form-control" v-model="PaymentDetails.total_amount" disabled>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="text-center input-group-append" v-if="!freeTrial" style="width: 100%;">
            <!-- <button
              class="btn btn-primary"
              @click="detailesSaved(),PayNow()"
              style="width: 100%; font-weight: 6fil00;background-color:#0aafe3;"
            >SUBMIT</button> -->
          </div>
          <div class="text-center input-group-append" v-else style="width: 100%;">
            <!-- <button class="btn btn-primary" id="saveAs"  @click="Save()"  style="width: 100%; font-weight: 700;" >Save</button> -->
            <button
              class="btn btn-primary"
              id="saveAs"
              @click="Save()"
              :disabled="buttonDisabled"
              style="width: 100%; font-weight: 700;"
            >Save</button>
          </div>
        </div>
        <p style="display: flex; align-items: center; justify-content: center;">
          If Already Purchased ?
          <a
            @click="login()"
            style="color:#567da5; cursor: pointer;"
          >&nbsp; Login</a>
        </p>
      </div>
      
      <div class="plancontainer">
        <div class="header">
          <span>Select Plan</span>
        </div>
        <div
          class="title"
        >Explore our Free Trial and discover the perfect plan for your needs.</div>
        <div class="customplan">
   
   <div class="row">
<div class="col-6 tab" @click="slideTo('unlimited')" :class="{ active: activeTab === 'unlimited' }">Unlimited Plan</div>
<div class="col-6 tab" @click="slideTo('customized')" :class="{ active: activeTab === 'customized' }">Customized User Plan</div>
<div class="col-6 slider" :style="sliderStyle"></div>
</div>
 </div>
 <div v-if="activeTab === 'unlimited'" class="unliplan">
        <div class="planContainer1">
          <input type="radio" style name="plan" id="plan1" value="0" v-model="offerVisible" />
          <label for="plan1">Annual</label>

          <div
            class="row"
            style=" justify-content: center;    justify-content: center;
    margin-top: -2%;
    width: 63%;
    margin-left: 29%;"
          >
            <div class="col-6 textamount" style>
              <span class="amount cross">{{this.formatNumber(this.dyanmicPrice1)}}/-</span>
            </div>
            <div class="col-6 textamount1" style>
              <span class="amount1">{{this.formatNumber(this.EffectivePrice)}}/-</span>
            </div>
            <div class="col-12 textamount1">
              <span class="decript">Coupon Code '{{ this.dynamicCouponCode }}'.</span>
            </div>
          </div>
        </div>
        <div class="planContainer1">
          <input type="radio" style name="plan" id="plan2" value="1" v-model="offerVisible" />
          <label for="plan2" style>3 Months</label>
          <span
            style="margin-left:62%;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 20px/27px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-000000);
text-align: right;
font: normal normal bold 20px/27px Open Sans;
letter-spacing: 0.4px;
color: #000000;
text-transform: capitalize;"
          >{{this.formatNumber(this.dyanmicPrice2)}}/-</span>
        </div>
        <div class="planContainer1">
          <input type="radio" style name="plan" id="plan3" value="2" v-model="offerVisible" />
          <label for="plan3">1 Months</label>
          <span
            style="margin-left:65%;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 20px/27px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-000000);
text-align: right;
font: normal normal bold 20px/27px Open Sans;
letter-spacing: 0.4px;
color: #000000;
text-transform: capitalize;  font-weight: 700 bolder;"
          >{{this.formatNumber(this.dyanmicPrice3)}}/-</span>
        </div>
   </div>
   <div v-else class="" style="position: relative;">
              <div class="row">
                <p
                  style="
                    color: #101010ce;
                    font-weight: 600;
                    margin-bottom: 7%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                  "
                >
                  Best Per User Subscription Plan in India = Rs 50 per User per
                  *month
                </p>
                <div class="col-4">
                  <lable style="color: #0aafe3">Enter User</lable>
                  <input   type="text"
               class="my-1 p-1"
               @click="selectPlan(3)"
               @input="updateResult"
               v-model="usernumber"
               placeholder="enter User above (10)"
               style="
                 width: 100%;
                 background-color: #70707012;
                 border: none; " min="1" max="100" step="1"  list="optionsList" />
              <datalist id="optionsList">
                
                <option v-for="option in options" :key="option" :value="option"></option>
              </datalist>
                </div>
                <div class="col-4">
                  <lable style="color: #0aafe3">Enter Year</lable>
                  <input
                    type="text"
                    v-model="Years"
                    @click="selectPlan(3)"
                    @input="updateResult"
                    class="my-1 p-1"
                    style="border: none;  background-color: #70707012; width: 100%"
                  />
                </div>
                <div
                  class="col-4 mb-4"
                  style="
                    display: flex;
                    align-items: center;

                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <lable style="color: #0aafe3">Plan Amount</lable>
                  <input
                    type="text"
                    class="my-1 p-1"
                    disabled
                    :value="PaymentDetails.original_amount"
                    style="border: none; width: 100%"
                  />
                </div>

                <span style="position: absolute;bottom: -5px;font-size: 0.6rem;">*All prices are excluded GST!</span>

              </div>
            </div>   

      </div>
      <figure
        v-if="offerVisible == 0 "
        class="figure-container"
        style="cursor: pointer; margin-bottom:6.5%;"
      >
        <img
          @click="offerOpen()"
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/offerBack.png"
          class="offer-image"
          alt="Image description"
        />
        <figcaption class="offer-caption">
          <img
            @click="offerOpen()"
            v-if="status === 0"
            class="offer-icon"
            src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconW.png"
            alt
          />
          <img
            v-else
            @click="offerOpen()"
            class="offer-icon"
            style="    margin-left: 5%;
    height: 9%;
    margin-top: -2%;
    width: 9%;"
            src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconW.png"
            alt
          />

          <span>
            <span
              @click="offerOpen()"
              v-if="status === 0"
              class="offer-text"
            >Offer applied to the bill&nbsp; &nbsp;</span>
            <span v-else @click="offerOpen()" class="offer-text1" style>
              &nbsp;&nbsp;Offers&nbsp;
              <small id="toggleText">are&nbsp;</small>Available
            </span>
          </span>
          <span v-if="status === 0" class="remove-button" @click.self="removeCoupon()">REMOVE</span>
        </figcaption>
      </figure>

      <div class="plancontainer2">
        <h4
          style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-25)/34px var(--unnamed-font-family-open-sans);
 letter-spacing: var(--unnamed-character-spacing-0);
 text-align: left;
 text-decoration: underline;
 font: normal normal bold 25px/34px Open Sans;
 letter-spacing: 0px;
 color: #0aafe3;
 text-transform: uppercase;margin-bottom:35px"
        >BILLING INFORMATION</h4>
        <div class="row">
          <div class="col-12 col-sm-12">
            <div style="    float: left;">
              <h4 class="rate">Product Name</h4>
            </div>
            <div style="float: right;">
              <h4 class="rate" style>Rate(₹)</h4>
            </div>
          </div>
          <div style="display: flex;align-items: center;justify-content: center;height: 10px; ">
            <hr style="width: 100%;height:2px;border:none;color:#333;background-color:#333;" />
          </div>
          <div class="col-12" style="margin-top: 5%;">
            <div class="disc" style="float: left;">Price</div>
            <div class="disc" style="float: right;">{{ this.formatNumber(this.PaymentDetails.original_amount)}}.00</div>
          </div>
          <div class="col-12" v-if="status === 0" style="margin-top: 4%;">
            <div class="disc" style="float: left;">Coupon Discount:</div>
            <div class="disc" style="float: right;">{{ this.PaymentDetails.discount }}</div>
          </div>
          <div class="col-12" v-if="status === 0" style="margin-top: 4%;">
            <div style="border: 1px dashed #707070;opacity: 1;"></div>
          </div>
          <div v-if="status === 0" class="col-12" style="margin-top: 4%;">
            <div class="disc" style="float: left; color: #101010;">Gross Amount:</div>
            <div
              class="disc"
              style="float: right; color: #101010;"
            >{{ this.PaymentDetails.Grossamount }}.00</div>
          </div>
          <div class="col-12" style="margin-top: 3%;">
            <div class="disc" style="float: left;">C-GST(9%):</div>
            <div class="disc" style="float: right;">{{ roundUpAmount(this.PaymentDetails.cgst) }}</div>
          </div>
          <div class="col-12" style="margin-top: 3%;">
            <div class="disc" style="float: left;">S-GST(9%):</div>
            <div class="disc" style="float: right;">{{ roundUpAmount(this.PaymentDetails.cgst) }}</div>
          </div>
          <div class="col-12" style="margin-top: 5%;">
            <div style="border: 1px dashed #707070;opacity: 1;"></div>
          </div>
          <div class="col-12" style="margin-top: 5%;">
            <div class="disc" style="float: left;">Total Payment:</div>
            <div class="disc" style="float: right;">{{ roundUpAmount(this.PaymentDetails.total_amount) }}</div>
          </div>
          <div class="col-12" style="margin-top: 8%;">
            <button
              class="PaynowButton"

              @click="PayNow()"
              style="background-color: #0aafe3; color: #ffffff; border: transparent; padding-top: 10px; border-radius: 8px; padding-bottom: 10px; width: 100%; font-weight: 600;"
            >PAY NOW</button>
            <!--               :class="{ disabled: PaynowVisible }"
              :disabled="PaynowVisible" -->
          </div>
        </div>
     </div>
    </div>

    <div
      v-if="showPopup1"
      ref="popupElement"
      class="popup1"
      @click.self="showPopup1 = false"
    >
      <div class="popup-content1">
        <div id="confettiContainer1"></div>
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrowClose.png"
          alt
          srcset
          @click="showPopup1 = false"
          style=" cursor: pointer;float:right; margin-top:5px; margin-right:5px; "
        />
        <div
          style="display:flex; position: relative; width:100%; margin-top:10%;  align-items:center;justify-content:center;"
        >
          <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/bigGreen.png" style="margin-bottom:5%" alt />
        </div>
        <h1
          style="font: var(--unnamed-font-style-normal) normal normal 30px/41px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal normal 30px/41px Open Sans;
      letter-spacing: 0.75px;
      color: #646464; margin-bottom:10%;"
        >"{{ this.couponCode }}" applied</h1>
        <h1
          style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 50px/45px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal bold 50px/45px Open Sans;
      letter-spacing: 1.25px;
      color: #363636;
      text-transform: uppercase;margin-bottom:5%;"
        >₹{{this.formatNumber(this.PaymentDetails.discount)}}/-</h1>

      

        <h1 v-if="this.extends1 == 2"
          style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 38px/40px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal 600 1rem Open Sans;
      letter-spacing: 0.95px;
      color: #363636;
      padding-left:6%;
      padding-right:6%;
              margin-bottom:8%;
      "
        >Congratulations...!! Your subscription validity has been extended to 1 year (9 months free).</h1>
        <h1 v-else
          style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 38px/40px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal 600 2.2rem Open Sans;
      letter-spacing: 0.95px;
      color: #363636;
      padding-left:6%;
      padding-right:6%;
              margin-bottom:8%;
      "
        >savings with this coupon</h1>

        <h1
          @click="showPopup1 = false"
          style=" cursor: pointer; font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 45px/61px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal bold 45px/61px Open Sans;
      letter-spacing: 1.13px;
      color: #FD6814;
      "
        >YAY!</h1>
      </div>
    </div>

   
    <div v-if="showPopup" class="popup" @click.self="showPopup = false">
      <div class="popup-content" style>
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrowClose.png"
          alt
          srcset
          @click="showPopup = false"
          style=" cursor: pointer;float:right; margin-top:5px; margin-right:5px; "
        />

        <div class style="  padding-left:2%;  padding-right:2% ;">
          <!-- <span style="float:right;  width: 50px; height: 50px; font-size:1rem; background-color:gray;display:flex;align-items:center; color:white;  justify-content:center; border-radius: 50%;"></span>  -->
          <h4 class="offerd">OFFERS</h4>
        </div>
        <div
          class
          style=" border:1PX;margin-left:2%;  margin-right:2% ;   border-style: dashed;height:1px; display:flex; align-items:center; border-color:#85a4c5 ; justify-content:center;  "
        ></div>
        <div
          class="search"
          style="display:flex;align-items:center;justify-content:center; height:100px;"
        >
          <input
            type="text"
            :class="getClassNames2"
            id="search"
            class="form-control"
            placeholder="Enter Coupon Code"
            v-model="couponCode"
            style=" font: var(--unnamed-font-style-normal) normal normal 20px/27px var(--unnamed-font-family-open-sans);
                      letter-spacing: var(--unnamed-character-spacing-0);
                      text-align: left;
                      font: normal normal normal 20px/27px Open Sans;
                      letter-spacing: 0px;
                      color: #5E5E5E;
                      opacity: 1;   background-color:#ffff;  width:320px;"
          />
          &nbsp;
          <button
            style="    border: 1px solid #FFFFFF; background-color:#0aafe3; color:#ffff;padding:7px 25px ; font-size:1rem;
                      border-radius: 8px;"
             @click="applyCoupon(item.subscription_id)"
          >APPLY</button>
        </div>
        <div
          class="search"
          style="display:flex;align-items:center;justify-content:center; height:40px;margin-bottom: 2%; background-color:#ECF4FC;border:1px solid #0aafe3;border-radius: 8px; margin-left:2%;margin-right:2%;"
        >
          <span
            style="display:flex;align-items:center; justify-content:center;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 16px/22px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;"
          >OR SELECTED AN OFFER (01)</span>
        </div>

        <div class="outerDiv" style>
          <div class="ApplyCouponCodeContainer" v-for="(item) in couponlist " v-bind:key="item.id">
            <div class="parDiv">
              <span>
                {{(this.calculateDiscountPercentage(this.PaymentDetails.original_amount, item.price))}}%
                <br />OFF
              </span>
            </div>

            <div class="container1">
              <span class="title">IBSF Offers</span>
              <button
                class="applyButton"
                @click="appliedCouponed(item.subscription_id , item.id)
            "
              >APPLY COUPON</button>
              <!-- <button v-else class="applyButton" @click="appliedCouponed1(item.id)">APPLY COUPON</button> -->

              <br />
              <span class="couponCode">Coupon Code: "{{item.code}}"</span>
              <br />
              <span
                v-if="item.id ==2"
                style="font-size: 0.5rem;"
              >Congratulations...!! Your subscription validity has been extended to 1 year (9 months free).</span>
              <span
                v-else
                class="couponDescription"
              >Use this coupon code and get {{this.calculateDiscountPercentage(this.PaymentDetails.original_amount, item.price)}}% off on the product.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl">
    <input type="hidden" name="key" v-model="mkey" size="64" />
    <input type="hidden" name="txnid" v-model="txnid" size="64" />
    <input type="hidden" v-if="normalAMount" name="amount" v-model="total_amount" size="64" />
    <input type="hidden" v-else name="amount" :value="this.amount" size="64" />

    <input type="hidden" name="productinfo" v-model="productinfo" size="64" />
    <input type="hidden" name="firstname" v-model="firstName" size="64" />
    <input type="hidden" name="service_provider" value="payu_paisa" size="64" />
    <input type="hidden" name="email" v-model="email" size="64" />
     <input type="hidden" name="phone" v-model="contactNumber" size="64" /> 
    <input type="hidden" name="lastname" v-model="lastName" size="64" /> 
    <input type="hidden" name="surl" v-model="surl" />
    <input type="hidden" name="furl" v-model="furl" />
    <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
  </form>-->

  <form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl">
    <input type="hidden" name="key" v-model="mkey" size="64" />
    <input type="hidden" name="txnid" v-model="txnid" size="64" />
    <input
      type="hidden"
      v-if="normalAMount"
      name="amount"
      v-model="this.PaymentDetails.total_amount"
      size="64"
    />
    <input type="hidden" v-else name="amount" :value="this.amount" size="64" />

    <input type="hidden" name="productinfo" v-model="productinfo" size="64" />
    <input type="hidden" name="firstname" v-model="firstName" size="64" />
    <input type="hidden" name="service_provider" value="payu_paisa" size="64" />
    <input type="hidden" name="email" v-model="email" size="64" />
    <input type="hidden" name="phone" v-model="contactNumber" size="64" />
    <input type="hidden" name="lastname" v-model="lastName" size="64" />
    <input type="hidden" name="surl" v-model="surl" />
    <input type="hidden" name="furl" v-model="furl" />
    <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
  </form>
</template>
<script>
import JSConfetti from "js-confetti";
import axios from "axios";
export default {
  data() {
    return {
      customamount:1,
      usernumber:1,
      selectedNumber: 10,
      options: [],
      customplan:[],
      is_freetrial: 0,
      plansss: [],
      readamount: [],
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      companyName: "",
      couponCode: "",
      companyAddress: "",
      country: "",
      state: "",
      city: "",
      search: "",
      salesManager: "",
      gst: "",
      createPassword: "",
      formError: "",
      messageClass: "",
      PaynowVisible: true,
      amountInfo: "",
      normalAMount: true,
      EffectivePrice:"",
      pincode: "",
      status: 1,
      selectedImage: "",
      isMobile: true,
      Years:1,

      fontSize: "16px",
      showPopup: false,
      showPopup1: false,
      showPopup2: false,
      showPopup3: false,

      // loading: false,
      responseStatus: null,
      responseStatus2: null,
      emailDisabled: false,
      offerVisible: 0,
      amount: "49999",
      showPassword: false,
      selectedPlan: "",
      buttonDisabled: true,
      dyanmicPrice1:"",
      dyanmicPrice2:"",
      dyanmicPrice3:"",
      dyanmicPrice4:"40",
      freeTrial: false,
      apiError: false,
      extends1:0,
      apiSuccess: false,
      loading: false,
      emailDisable: false,
      country_id: "101",

      PaymentDetails: {
        discount: "",
        original_amount: "49999",
        total_amount: "",
        sgst: "",
        cgst: "",
        discount_amount: "",
        Grossamount: "",
      },
      activeTab: 'unlimited',
      couponlist: [],
      dynamicCouponCode:"",
      state_id: "",
      city_id: "",
      countries: [],
      productinfo: "Ibt",
      states: [],
      cities: [],
      virify: true,
      payuUrl: "https://secure.payu.in/_payment",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      surl: window.location.origin + "/success",
      furl: window.location.origin + "/",
      txnid: this.makeid(),
      calculatedAmount: 0,
    };
  },
  computed: {
    sliderStyle() {
      return {
        transform: this.activeTab === 'unlimited' ? 'translateX(0)' : 'translateX(95%)',
      };
    },
    containerStyle() {
      return {
        backgroundColor: "#ffff",
        // color: '',
        borderRadius: "0 14px 14px 0",
        paddingRight: "50px",
        paddingLeft: "50px",
        height: this.selectedPlan ? "500px" : "auto",
        transition: "height 0.3s ease-in-out",
      };
    },
    getClassNames() {
      const classNames = [];

      if (this.responseStatus === "success") {
        classNames.push("success-border");
      } else if (this.responseStatus === "error" && this.email) {
        classNames.push("error-border", "shake");
      }

      return classNames.join(" ");
    },
    getClassNames2() {
      const classNames = [];

      if (this.responseStatus2 === "success") {
        classNames.push("success-border");
      } else if (this.responseStatus2 === "error") {
        classNames.push("error-border", "shake");
      }

      return classNames.join(" ");
    },
  },
  watch: {
    selectedNumber(newValue) {
      this.updateOptions(newValue);
    },
    offerVisible(newVal) {
      // Perform actions based on the selected radio button
      if (newVal === "0") {
        // Code for Option 1 selected
        this.offerVisible = 0;
        this.plansss = this.readamount;
        this.showAmount();

        // Set this.offerVisible = 0 or perform any other logic
      } else if (newVal === "1") {
        // Code for Option 2 selected
        // this.offerVisible = 1;
        this.showAmount1();
        this.status = 1;
        this.plansss = this.readamount1;

        // Set this.offerVisible = 1 or perform any other logic
      } else if (newVal === "2") {
        this.offerVisible = 2;
        this.showAmount2();
        this.status = 1;
        this.plansss = this.readamount2;
        // Code for Option 3 selected
        // Set this.offerVisible = 2 or perform any other logic
      }
      else if (newVal === "3") {
        this.offerVisible = 3;
        this.selectPlan(3)
        // alert('alert');
        // this.showAmount2();
        this.status = 1;
        // this.plansss =this.customplan.find(plan => plan.id);
        console.log( this.plansss);
        // Code for Option 3 selected
        // Set this.offerVisible = 2 or perform any other logic
      }
    },
  },
  methods: {
    updateOptions(value) {
      this.options = [];
      // this.options.push('1 - 10');
     
     
      if (this.usernumber == '10') {
        this.usernumber = 10;
      } 
        // alert(' ' , this.usernumber);
      this.options.push('1 - 10');
      // alert(this.usernumber);
      for(let i = 11 ; i<=100 ; i++){

            this.options.push(i);
      
          }
    },
    slideTo(tab) {
      this.activeTab = tab;
      if (tab === "unlimited") {
        this.offerVisible = 0;
        this.showAmount();
      } else {
        this.offerVisible = 1;
        this.updateResult();
      }
    },
    // updateResult() {
    //   if(this.usernumber == '1 - 10'){
    //   this.usernumber = 10;
    //  }
    //   this.offerVisible = 3;

    //   this.selectPlan(3);
    //   if (
    //     this.customamount == 1 ||
    //     this.customamount == 2 ||
    //     this.customamount == 3
    //   ) {
    //     this.customamount = this.customplan[0].id;
    //   }
    //   axios
    //     .get(
    //       `https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan/${this.customamount}`
    //     )
    //     .then((res) => {
    //       this.selectPlan(3);

    //       this.plansss = res.data.data.id;
    //       this.PaymentDetails.original_amount =
    //         parseInt(this.usernumber) * res.data.data.amount * this.Years * 12;
    //       this.PaymentDetails.cgst =
    //         this.PaymentDetails.original_amount * (9 / 100);
    //       this.PaymentDetails.sgst =
    //         this.PaymentDetails.original_amount * (9 / 100);
    //       this.PaymentDetails.total_amount =
    //         this.PaymentDetails.sgst +
    //         this.PaymentDetails.cgst +
    //         this.PaymentDetails.original_amount;
    //       console.log(res.data.data.amount);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   // const amount =
    // },
    updateResult() {
      console.log('hello');  
     if(this.usernumber == '1 - 10'){
      this.usernumber = 10;
     }
      this.offerVisible = 3;

      this.selectPlan(3);
      if (
        this.customamount == 1 ||
        this.customamount == 2 ||
        this.customamount == 3
      ) {
        this.customamount = this.customplan[0].id;
      }
      axios
        .get(
          `https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan/${this.customamount}`
        )
        .then((res) => {
          this.selectPlan(3);

          this.plansss = res.data.data.id;
          if(this.usernumber <= 10){

                this.PaymentDetails.original_amount =
                parseInt(10) * res.data.data.amount * this.Years * 12;
                this.PaymentDetails.cgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.sgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.total_amount =
                this.PaymentDetails.sgst +
                this.PaymentDetails.cgst +
                this.PaymentDetails.original_amount;
                console.log(res.data.data.amount);
                  }
                  
                  else{
                    this.PaymentDetails.original_amount =
                parseInt(this.usernumber) * res.data.data.amount * this.Years * 12;
                this.PaymentDetails.cgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.sgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.total_amount =
                this.PaymentDetails.sgst +
                this.PaymentDetails.cgst +
                this.PaymentDetails.original_amount;
                console.log(res.data.data.amount);
                  }
        })
        .catch((error) => {
          console.log(error);
        });
      // const amount =
    },
    calculateDiscountPercentage(originalPrice, discountedPrice) {
      // console.log("thow ",originalPrice , discountedPrice);
            const percentageOff = (discountedPrice / originalPrice) * 100;

      return percentageOff.toFixed(0);
    },
  
    PayNow() {
      const formData = new FormData();
     formData.append("companylogo", this.selectedImage);

      if (this.firstName) {
        formData.append("first_name", this.firstName);
      }

      if (this.lastName) {
        formData.append("last_name", this.lastName);
      }

      if (this.email) {
        formData.append("email", this.email);
      }
      if(this.usernumber){
        formData.append('users', this.usernumber);
      
      }
      if (this.contactNumber) {
        formData.append("mobile_no", this.contactNumber);
      }

      if (this.createPassword) {
        formData.append("password", this.createPassword);
      }

      if (this.companyAddress) {
        formData.append("company_address", this.companyAddress);
      }

      if (this.gst) {
        formData.append("gst_number", this.gst);
      }

      if (this.plansss) {
        formData.append("subscription_planid", this.plansss);
      }

      if (this.couponCode) {
        formData.append("code", this.couponCode);
      }

      if (this.companyName) {
        formData.append("company_name", this.companyName);
      }

      if (this.country_id) {
        formData.append("country", this.country_id);
      }

      if (this.state_id) {
        formData.append("state", this.state_id);
      }

      if (this.city_id) {
        formData.append("city", this.city_id);
      }

      if (this.Pincode) {
        formData.append("pincode", this.Pincode);
      }

      if (this.salesManager) {
        formData.append("sales_manager_name", this.salesManager);
      }
      axios
        .post("https://salesforceapi.infinitybrains.com/api/store-vendor", formData)
        .then((res) => {
          localStorage.setItem('PD' ,  JSON.stringify(this.PaymentDetails) );
          console.warn(res.data);
          // alert(this.plansss);
          var data =
            this.mkey +
            "|" +
            this.txnid +
            "|" +
            this.PaymentDetails.total_amount +
            "|" +
            this.productinfo +
            "|" +
            this.firstName +
            "|" +
            this.email +
            "|||||||||||";
          var sha512 = require("js-sha512");
          var salt = "3oFxUMtWG2";
          var hash = sha512(data + salt);

          console.log(hash);
          console.log("Here Is yourData", data);

          document.getElementById("hash").value = hash;
          document.getElementById("paymentForm").submit();
        })
      
        .catch((error) => {
          this.formError = error.response.data.message;
          this.messageClass = "danger";
        });
      // localStorage.clear();
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    readallCoupon() {
      axios.get("https://salesforceapi.infinitybrains.com/api/super-admin/coupon").then((res) => {
        console.log("data3", res.data.data[0]);
        
        this.couponlist = res.data.data;
        console.log(this.couponlist);
      });
    },
    offerOpen() {
      this.showPopup = true;
    },
    roundUpAmount(amount) {
      const roundedAmount = Number(amount).toFixed(2);
      return roundedAmount;
    },

    selectPlan(value) {
    this.offerVisible = value;
  },

    getdynamiccustomAmount(){

      // axios.get(`https://uatapihrms.redjinni.com/api/subscription-plans?filter={'is_user_plan': '0', 'status': '1'}`).then((res)=>{
      axios.get(`https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan?filter={"is_user_plan": "1"}`).then((res)=>{
        this.customplan = res.data.data.data

        console.log(this.customplan);
      }).catch();

    },

     appliedCouponed(subscription_id, id) {
        
        if(id == 2){
              this.extends1 = 2 ;
          }else{
            this.extends1 = 0;
          }
      
      this.showPopup1 = true;
       
      this.status = 0;

      // console.log("coupon id",id)
      // eslint-disable-next-line no-debugger
      //  debugger;
      this.status = 0;
      axios
        .get("https://salesforceapi.infinitybrains.com/api/super-admin/coupon/" + id, {})
        .then((res) => {
          console.log("data3", res.data);
          this.couponCode = res.data.data.code;
          this.applyCoupon(subscription_id, id);
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  
   applyCoupon(subscription_id ,id) {
      console.log(subscription_id);
      axios
        .post("https://salesforceapi.infinitybrains.com/api/applycoupon", {
          code: this.couponCode,
          subscription_id: subscription_id,
        })

        .then((res) => {
          console.log(res.data.data);
          this.responseStatus2 = "success";
          this.status = 0;
         this.showPopup1 = true;
          const jsConfetti = new JSConfetti();
          jsConfetti.addConfetti();
          this.descountedPrice = res.data.data;
          this.PaymentDetails.original_amount = res.data.data.original_price;
          this.PaymentDetails.total_amount = res.data.data.final_amount;
          this.PaymentDetails.sgst = res.data.data.sgst;
          this.PaymentDetails.cgst = res.data.data.sgst;
          this.PaymentDetails.Grossamount = res.data.data.discount_price;
          this.PaymentDetails.discount = res.data.data.discount;
          this.apiSuccess = true;
          this.apiError = false;
          this.showPopup = false;
        })
        .catch(() => {
          this.responseStatus2 = "error";
          this.normalAMount = true;
          this.apiError = true;
          this.apiSuccess = false;
        });
    },
    formatNumber(number) {
        number = number.toString().replace(/\D/g, '');
        let formattedNumber = '';
        let commaCount = 0;

        for (let i = number.length - 1; i >= 0; i--) {
          formattedNumber = number[i] + formattedNumber;
          commaCount++;

          if (commaCount === 3 && i > 0) {
            formattedNumber = ',' + formattedNumber;
            commaCount = 0;
          }
        }

        return formattedNumber;
      },
   scrollToZeroIndex() {
      const targetElement = document.getElementById("maincontainer");
      if (targetElement) {
        // Scroll to the top of the element
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth", // Add smooth scrolling effect if desired
        });
      }
    },
    removeCoupon() {
    
      this.showPopup1 = false;
      this.showAmount();
      this.status = 1;
    },
    ReadallAmount() {
      axios
        .get("https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan")
        .then((res) => {
          console.log("from read all amount", res.data.data);
          // Call the showAmount function with the first item's ID
          // this.readamount = res.data.data.data;
          this.dyanmicPrice1 = res.data.data.data[0].amount;
        this.dyanmicPrice2 = res.data.data.data[1].amount;
        this.dyanmicPrice3 = res.data.data.data[2].amount;
          console.log(this.readamount);
        });
    },

    showAmount() {
      axios
      .get("https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan")
        .then((res) => {
          this.readamount =res.data.data.data[0];
          this.plansss = this.readamount.id;
          // this.readamount1 = res.data.data[1];
          // this.readamount2 = res.data.data[2];
          this.amountInfo = this.readamount;
          this.amount = this.readamount.amount;
          console.log("data1",res.data.data.data[0]);
            this.amountInfo = res.data.data.data[0];
            this.amount = res.data.data.data[0].amount;
            this.PaymentDetails.original_amount =  res.data.data.data[0].amount;
            this.PaymentDetails.total_amount = parseFloat( this.PaymentDetails.original_amount) +   this.PaymentDetails.original_amount * (9 / 100) * 2;
            this.PaymentDetails.sgst =(res.data.data.data[0].amount)* (9 / 100);
            this.PaymentDetails.cgst =(res.data.data.data[0].amount)* (9 / 100);
            this.PaymentDetails.Grossamount = res.data.data.data[0].amount;
        });
      console.log("yash hello", this.readamount.amount);
    },
    showAmount1() {
      axios
        .get("https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan")
        .then((res) => {
          // this.readamount = res.data.data[0];
          this.readamount1 = res.data.data.data[1];
          // this.readamount2 = res.data.data[2];
          this.plansss = this.readamount1.id;
          this.amountInfo = this.readamount1;
          this.amount = this.readamount1.amount;
          this.PaymentDetails.original_amount = this.readamount1.amount;
          this.PaymentDetails.total_amount = parseFloat( this.PaymentDetails.original_amount) +   this.PaymentDetails.original_amount * (9 / 100) * 2;
             this.PaymentDetails.sgst =(res.data.data.data[1].amount)* (9 / 100);
            this.PaymentDetails.cgst =(res.data.data.data[1].amount)* (9 / 100);
          this.PaymentDetails.Grossamount = this.readamount1.amount;
        });
    },
    roundUpAmount(amount) {
      const roundedAmount = Number(amount).toFixed(2);
      return roundedAmount;
    },
    showAmount2() {
      axios
        .get("https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan")
        .then((res) => {
          this.readamount2 = res.data.data.data[2];
          this.plansss = this.readamount2.id;
          this.amountInfo = this.readamount2;
          this.amount = this.readamount2.amount;
          this.PaymentDetails.original_amount = this.readamount2.amount;
          this.PaymentDetails.total_amount = parseFloat( this.PaymentDetails.original_amount) +   this.PaymentDetails.original_amount * (9 / 100) * 2;
              this.PaymentDetails.sgst =(res.data.data.data[2].amount)* (9 / 100);
            this.PaymentDetails.cgst =(res.data.data.data[2].amount)* (9 / 100);
          this.PaymentDetails.Grossamount = this.readamount2.amount;
        });
    },
    // showAmount(id) {
    //   axios
    //     .get(`https://uatapihrms.redjinni.com/api/subscription-plans/${id}`)
    //     .then((res) => {
    //       console.log("data1", res.data.data);
    //       if (res.data.data && res.data.data.amount) {
    //         this.amountInfo = res.data.data;
    //         this.amount = res.data.data.amount[0];
    //         console.log(this.amount);
    //         this.PaymentDetails.original_amount = res.data.data.amount;
    //         this.PaymentDetails.total_amount = res.data.data.totalamount;
    //         this.PaymentDetails.sgst = res.data.data.sgst;
    //         this.PaymentDetails.cgst = res.data.data.sgst;
    //         this.PaymentDetails.Grossamount = res.data.data.amount;
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    // showAmount1(id) {
    //   axios
    //     .get(`https://uatapihrms.redjinni.com/api/subscription-plans/${id}`)
    //     .then((res) => {
    //       console.log("data2", res.data.data);
    //       if (res.data.data && res.data.data.amount) {
    //         this.amountInfo = res.data.data;
    //         this.amount = res.data.data.amount;
    //         this.PaymentDetails.original_amount = res.data.data.amount;
    //         this.PaymentDetails.total_amount = res.data.data.totalamount;
    //         this.PaymentDetails.sgst = res.data.data.sgst;
    //         this.PaymentDetails.cgst = res.data.data.sgst;
    //         this.PaymentDetails.Grossamount = res.data.data.amount;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // showAmount2(id) {
    //   axios
    //     .get(`https://uatapihrms.redjinni.com/api/subscription-plans/${id}`)
    //     .then((res) => {
    //       console.log("data3", res.data.data);
    //       if (res.data.data && res.data.data.amount) {
    //         this.amountInfo = res.data.data;
    //         this.amount = res.data.data.amount;
    //         this.PaymentDetails.original_amount = res.data.data.amount;
    //         this.PaymentDetails.total_amount = res.data.data.totalamount;
    //         this.PaymentDetails.sgst = res.data.data.sgst;
    //         this.PaymentDetails.cgst = res.data.data.sgst;
    //         this.PaymentDetails.Grossamount = res.data.data.amount;
    //       }
    //     });
    // },
    getData() {
      // alert("from getdata")
      axios
        .get("https://api.restroworld.com/api/countries?is_light=true")
        .then((result) => {
          this.countries = result.data.data;
          // console.log(this.countries)

          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getState() {
      axios
        .get(
          "https://api.restroworld.com/api/states_of_country?is_light=true",
          {
            params: {
              country_id: this.country_id,
            },
          }
        )
        .then((result) => {
          this.states = result.data.data;
          this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCity() {
      axios
        .get("https://api.restroworld.com/api/cities_of_state?is_light=true", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleFileUpload1() {
      const fileInput = this.$refs.fileInput;
      console.log(fileInput);

      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];
        this.selectedImage = file;
        this.selectedFileName = file.name;
        console.log(this.selectedImage);
        console.log(this.selectedImage);
      } else {
        this.selectedImage = null;
        this.selectedFileName = "";
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login() {
      const data = {
          name: 'John',
          age: 30,
        };
        const queryParameters = new URLSearchParams(data).toString();
        const deepLinkUrl = `ibsalesforce://open?${queryParameters}`;
  
        // Try to open the deep link URL
        window.location.href = deepLinkUrl;
  
        // Redirect to the Play Store if the deep link fails
        setTimeout(() => {
          const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce';
          window.location.href = playStoreUrl;
        }, 3000); // Change the timeout value as needed
          (this.firstName = ""),
            (this.lastName = ""),
            (this.email = ""),
            (this.contactNumber = ""),
            (this.companyName = ""),
            (this.couponCode = ""),
            (this.companyAddress = ""),
            (this.salesManager = ""),
            (this.gst = ""),
            (this.createPassword = ""),
        
            (this.pincode = "");
    
    },
    AutoFillData() {
      const storedData = JSON.parse(localStorage.getItem("formData"));
      if (storedData) {
        // this.inputValue = storedData;
        this.firstName = storedData.firstName;
        this.lastName = storedData.last_name;
        this.email = storedData.email;
        this.contactNumber = storedData.mobile_no;
        this.createPassword = storedData.password;
        this.companyAddress = storedData.company_address;
        this.gst = storedData.gst_number;
        this.companyName = storedData.company_name;
        this.country_id = storedData.country;
        this.state_id = storedData.state;
        this.city_id = storedData.city;
        this.Pincode = storedData.pincode;
        this.salesManager = storedData.sales_manager_name;
      }
    },
  },
  // created() {
  //   var userDetail; // Declare the variable outside of any function or block scope
  //   var userDetaileeed = localStorage.getItem("userDetails");
  //   userDetail = JSON.parse(userDetaileeed);
  //   console.log(userDetail.firstName);
  // },

  mounted() {
    this.showPopup1 = false;
    this.plansss = this.readamount;
    console.log(this.plansss, "from plansss");
    this.getdynamiccustomAmount();
    this.updateOptions(this.selectedNumber);
    // this.updateResult();
    this.selectPlan(0);
    axios
        .get("https://salesforceapi.infinitybrains.com/api/super-admin/coupon").then((res)=>{
          // alert( res.data.data[0].code);
          this.dynamicCouponCode = res.data.data[0].code;
          // alert(res.data.data[0].subscription_id);
          axios
        .post("https://salesforceapi.infinitybrains.com/api/applycoupon", {
          code: res.data.data[0].code,
          subscription_id: res.data.data[0].subscription_id,
        })

        .then((res) => {
          console.log(res.data)
          this.EffectivePrice = res.data.data.discount_price ; 

        });

        });

    
        
    this.scrollToZeroIndex();
    this.ReadallAmount();
    this.showAmount();
    this.readallCoupon();
    this.getData();
    this.AutoFillData();
    // this.getCity();
    // this.getState();
    // const serializedData = localStorage.getItem("userDetails");
    // const userDetails = JSON.parse(serializedData);
    // this.PaymentDetails.firstName = userDetails.firstName;
    // this.PaymentDetails.lastName = userDetails.lastName;
    // this.PaymentDetails.email = userDetails.email;
    // this.PaymentDetails.productinfo = userDetails.productinfo;
    // console.log(this.PaymentDetails);
  },
};
</script>
<style scoped>

.custom-file-input {
  position: relative;
  overflow: hidden;
}

.custom-file-input::after {
  content: 'browse';
  width: 10vh;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0aafe3;
    padding: 1px;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
    border-radius:8px;
    transition: background-color 0.3s ease;
}

.custom-file-input:hover::after {
  background-color: #0aafe3;
}

.custom-file-input:focus::after {
  outline: none;
  background-color: #b9b9b9;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  width: 0;
  height: 0;

}

.custom-file-input::before {
  content: attr(placeholder);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5em;
  background-color: transparent;
  color: #999999;
  pointer-events: none;
  transition: color 0.3s ease;
}

.custom-file-input:focus::before,
.custom-file-input:not(:placeholder-shown)::before {
  color: #ffffff;
}

.custom-file-input::file-selector-button {
  visibility: hidden;
  width: 0;
  height: 0;
}
.container {
  background-color: #f6f6f6;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  max-width: 754px;
}
.maincontainer {
  background-color: #f6f6f6;
}
.left-column {
  background-color: #ffff;
  max-width: 910px;
  border-radius: 14px;
  padding-right: 50px;
  padding-left: 50px;
}
.arrow-image2 {
  float: right;
  margin-top: -5%;
  margin-right: 10px;
}
.arrow-image1 {
  float: right;
  margin-top: -4%;
  margin-right: 10px;
}
.successCoupon {
  background: #ecfff3 0% 0% no-repeat padding-box;
  border: 2px solid #009436;
  border-radius: 15px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  padding: 12px;
}
.successCoupon span {
  margin-top: 0.5%;
}
.SCA1 {
  font: normal bold 0.9rem Arial;
  letter-spacing: 0px;
  color: #009436;
  opacity: 1;
  margin-right: 10px;
  margin-left: 8px;
}

.SCA2 {
  font: normal normal 0.9rem Arial;
  letter-spacing: 0px;
  color: #009436;
  opacity: 1;
}
.plancontainer {
  height: fit-content;
  width: 100%;
  background-color: white;
  margin-bottom:5%;
  border-radius: 20px;
  padding-top:5%;
  padding-bottom:5%;
  padding: 2%;
}
.plancontainer2 {
  height: fit-content;
  width: 100%;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 20px;
  padding: 12px 15px;
}
.title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    0.72rem var(--unnamed-font-family-open-sans);
  text-align: center;
  font: normal normal 600 0.72rem Open Sans;
  letter-spacing: 0.28px;
  color: #555555;
  margin-bottom: 20px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-25) / 34px var(--unnamed-font-family-open-sans);
  text-align: center;
  text-decoration: underline;
  font: normal normal bold 25px/34px Open Sans;
  letter-spacing: 0.5px;
  color: #0aafe3;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.textamount {
  display: flex;
  justify-content: flex-start;
}

.textamount1 {
  display: flex;
  justify-content: center;
}
.decript {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    12px/17px var(--unnamed-font-family-open-sans);
  text-align: right;
  font: normal normal bold 12px/17px Open Sans;
  letter-spacing: 0.24px;
  color: #0aafe3;
  text-transform: capitalize;
  margin-top: 2.5%;
}
.amount {
  font: normal normal bold 4vmin Open Sans;
  letter-spacing: 0.4px;
  color: #000000b9;
  text-transform: capitalize;
  margin-left: 12.5%;
  margin-top: 8.5%;
}
.amount1 {
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0.4px;
  color: #000000;
  text-transform: capitalize;
  margin-right: 12.5%;
}
.cross {
  position: relative;
  display: inline-block;
}
.cross::before,
.cross::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.cross::before {
  border-bottom: 1px solid #ff0000;
  -webkit-transform: skewY(-8deg);
  transform: skewY(-8deg);
}

.planContainer1 {
  display: flex;
  align-items: center;
  padding-left: 25px;
  /* padding-right:25px; */
  width: 98%;
  height: 67px;
  background: #e9f4ff;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 15px;
  margin-left: 2px;
  margin-right: 0px;
}
.planContainer1:last-child {
  margin-bottom: 0px;
}

.planContainer1 label {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    20px/27px var(--unnamed-font-family-open-sans);
  color: var(--unnamed-color-000000);
  text-align: right;
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0.4px;
  color: #000000;
  text-transform: capitalize;
  color: #000000;
  /* font-size: 1.5rem; */
  width: fit-content;
  position: absolute;
  margin-left: 40px;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 25px;
  height: 25px;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 17px;
  height: 17px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #0aafe3;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
.inner-container {
  text-align: center;
  margin-top: 6%;
  
  /* Styles for the inner container */
}

.inner-container h2 {
  font-weight: 700;
  font-size: 2.5rem;
}
.plan-item {
  border-radius: 5px;
  border: transparent;
  height: 70px;
  background-color: #ffff;
  color: black;
}

.plan-item p {
  font-size: 1.2rem;
  float: left;
  margin-top: 4.5%;
  padding-left: 10px;
}
.inner-container p {
  font-weight: 700;
  font-size: 1.5rem;
}

.figure-container {
  position: relative;
  width: 100%;
}

.offer-image {
  width: 100%;
}

.offer-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  font-family: "Merienda", cursive;
  font-size: 1rem;
  line-height: 42px;
  letter-spacing: 0;
  color: #ffffff;
  text-align: left;
  opacity: 1;
  text-shadow: 0px 1px 3px #53c6e9;
}

.offer-icon {
  margin-left: 3%;
  height: 7%;
  width: 7%;
}

.offer-text {
  font-size: 15px;
  margin-top: 2px;
  margin-left: 5px;
}
.offer-text1 {
  font-size: 24px;
  margin-top: 2px;
  margin-left: 11px;
}
.remove-button {
  border: 2px dashed #ffffffc5;
  color: white;
  background-color: transparent;
  cursor: pointer;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    16px/22px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfcdc;
  text-transform: uppercase;
  padding: 4px 17px;
  margin-left: -2%;
  border-radius: 8px;
  text-shadow: none;
}
.disc {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    17px/23px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 17px/23px Open Sans;
  letter-spacing: 0px;
  color: #3d4141ea;
  opacity: 1;
}
.rate {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    21px/28px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 21px/28px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;
}
.offerd {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 30px/41px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  height: 101px;
  margin-left: 6%;
}
.popup-content {
  border-radius: 15px;
  background-color: #ffffff;
  /* padding: 20px; */
  width: 46rem;
  max-width: 100%;

  height: 46rem;
  /* max-height: 60rem; */
  max-height: 80%;
}
.error-border {
  border-color: red;
}
.success {
  color: rgb(59, 201, 59);
}

.danger {
  color: rgb(236, 120, 120);
}

.success-border {
  border-color: rgb(68, 216, 68);
}
.shake {
  animation: shake 0.2s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
.custom-button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 4px;

  padding-right: 4px;
}

.custom-button {
  border-radius: 8px;
  border: none;
  height: 100%;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0aafe3;
  color: white;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/bgB.png"); */
  background-color: #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outerDiv {
  overflow: auto;
  height: 38vh;
  max-height: fit-content;
  /* Set the desired percentage-based height for the outerDiv */
}

.outerDiv {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 40vh; /* Set the desired percentage-based height for the outerDiv */
}

@media (max-width: 767px) {
  .ApplyCouponCodeContainer {
    /* Adjusted styles for smaller screens */
    grid-template-columns: 1fr;
    height: fit-content;
    padding: 8px;
    margin: 2% 0;
    line-height: 1;
  }
}
.left-column{
  padding-left: 0%;
  padding-right:0% ;
}
.ApplyCouponCodeContainer {
  height: 91px;
  padding-top: 8px;
  padding-left: 8px;
  padding: 8px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  /* justify-content: space-between; */

  /* align-items: center; */
}
.parDiv {
  line-height: 1;
  background-color: #0aafe3;
  height: 71px;
  border-radius: 8px;
  padding: 10px;
  width: 71px;
  font-family: "Merienda", cursive;
  text-align: left;
  font-size: 1.6rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.title1 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    17px/23px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 17px/23px Open Sans;
  letter-spacing: 0px;
  color: #272727;
}
.couponCode {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    12px/17px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;
}

.couponDescription {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    12px/17px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #5e5e5e;
}
.container1 {
  margin-left: 2%;
  width: 100%;
}
.applyButton {
  float: right;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border: 1px solid #0aafe3;
  border-radius: 5px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    11px/23px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal 600 11px/23px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;
  text-transform: uppercase;
}
.popup-content1 {
  border-radius: 15px;
  background-color: #ffffff;
  /* padding: 20px; */
  width: 32rem;
  max-width: 100%;

  height: 35rem;
  /* max-height: 60rem; */
  max-height: 80%;
  background-image: url("./../../ASSET/IBT_FREE_TRIAL_SCREEN/backimges.png");
}
.popup-content2 {
  border-radius: 15px;
  background-color: #ffffff;
  /* padding: 20px; */
  width: 43rem;
  max-width: 100%;
  height: 48rem;
  /* max-height: 60rem; */
  max-height: 95%;
  background-image: url("./../../ASSET/IBT_FREE_TRIAL_SCREEN/backimges.png");
}
.popup-content3 {
  border-radius: 15px;
  background-color: #ffffff;
  /* padding: 20px; */
  width: 43rem;
  max-width: 100%;
  height: 48rem;
  /* max-height: 60rem; */
  max-height: 95%;
  background-image: url("./../../ASSET/IBT_FREE_TRIAL_SCREEN/backimges.png");
}
.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/bgB.png"); */
  background-color: #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/bgB.png"); */
  background-color: #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/bgB.png"); */
  background-color: #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab {
  padding: 2.3%;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  font-weight: 900;
  color: #ffffff;
  z-index: 1;
}



.slider {
  padding: 4.8%;
  border-radius: 24px;
  height: max-content;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 0;
  font-weight: 900;
  background-color:#00abf5;
  transition: transform 0.3s ease-in-out;
}

.customplan .row {
  margin: 5% 0%;
  border-radius: 26px;
  padding: 1%;
  position: relative;
  background: #bcbcbcc0;
}

</style>