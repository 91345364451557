<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <body>
    <div class="container" style="background-color: white; padding: 4rem">
      <h1>Deletion Policy for ibsales</h1>
      <h2>Right to Deletion</h2>

      <p>
        You have the right to request the deletion of the personal information
        that we have collected, subject to certain exceptions under applicable
        law.
      </p>

      <h2>Process for Making Deletion Requests</h2>

      <p>
        To request the deletion of your personal information, please contact us
        at contact@infinitybrains.com with your request. We may require
        additional information to verify your identity before processing your
        request.
      </p>

      <h2>Response Timing and Format</h2>

      <p>
        We will confirm receipt of your request within 30 business days and
        provide information about how we will process your request. We will
        generally respond to your request within 45 days, but we may need
        additional time to process your request, subject to applicable law.
      </p>

      <h2>Exceptions</h2>

      <p>
        We may deny your deletion request if retaining the information is
        necessary for us or our service providers to:
      </p>

      <p>
        Complete the transaction for which we collected the personal
        information, provide a good or service that you requested, take actions
        reasonably anticipated within the context of our ongoing business
        relationship with you, or otherwise perform our contract with you.
        Detect security incidents, protect against malicious, deceptive,
        fraudulent, or illegal activity, or prosecute those responsible for such
        activities. Debug products to identify and repair errors that impair
        existing intended functionality. Comply with a legal obligation. Make
        other internal and lawful uses of that information that are compatible
        with the context in which you provided it. Verification
      </p>

      <p>
        We may request additional information to verify your identity and
        authorize the deletion request.
      </p>

      <h2>Authorized Agents</h2>

      <p>
        You may designate an authorized agent to make a request on your behalf.
        We may require the authorized agent to provide proof of authorization
        and verify their own identity.
      </p>

      <h2>Record Retention</h2>

      <p>
        We may retain a record of your request and our response for a reasonable
        period of time, as required by law or for legitimate business purposes.
      </p>

      <h2>Changes to the Deletion Policy</h2>

      <p>
        We may update this Deletion Policy from time to time. If we make
        material changes, we will provide notice on our website or by email.
        Your continued use of our app after the changes are made indicates your
        acceptance of the updated Deletion Policy.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about our Deletion Policy or our
        app, please contact us at <a href="#">contact@infinitybrains.com</a>.
      </p>
    </div>
  </body>
</template>

<!-- Deletion Policy -->

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "deletionpolicy",
};
</script>

<style scoped>
p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.2rem;
  color: #666;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.container {
  width: 80%;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
  color: #444;
}

h1 {
  font-size: 2em;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
}

h2 {
  font-size: 1.5em;
  margin-top: 20px;
}

p {
  line-height: 1.6;
  margin: 10px 0;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
