import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// mouting point for the whole app

import App from "@/App.vue";
// import Vue from 'vue';
import "./plugins/analytics";
// layouts

import home from "./components/Home.vue";
import NewUser from "./components/NewUser.vue";
import WatchVideo from "./components/WatchVideo.vue";
import BuyForm from "./components/BuyForm.vue";
import Success from "./components/success.vue";
import deletionpolicy from "./components/deletionpolicy.vue";
import freeTrial from "./components/freeTrial.vue";
import purchaseplan from "./components/phonePurchase.vue";

// verify and logo payment
import VerifyEmail from "@/components/VerifyEmail";
import LogoPayment from "@/components/LogoPayment";

// import plan and add remover
import AddRemoverPlan from "@/components/addRemover/AddRemoverPlan";

// Temporary Plan Payment
// import Temporary_Plan_Payment from "@/components/Temporary_plan_payment/Temporary_Plan_Payment.vue";
// import Temporary_Plan_Design from "@/components/Temporary_plan_payment/Temporary_Plan_Design.vue";
// import Temporary_Plan_Billing_Design from "@/components/Temporary_plan_payment/Temporary_plan_billingDesign.vue";

// import Index from "@/views/Index.vue";
import "bootstrap/dist/css/bootstrap.css";
// routes
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap.js'

import privacypolicy from "./components/PrivacyPolicy.vue";

// <!-- Google tag (gtag.js) -->

const routes = [
	{
		path: "/",
		component: home,
	},
	{
		path: "/newUser",
		component: NewUser,
	},
	{
		path: "/WatchVideo",
		component: WatchVideo,
	},
	{
		path: "/buy",
		component: BuyForm,
	},
	{
		path: "/success",
		component: Success,
	},
	{
		path: "/freeTrial",
		component: freeTrial,
	},
	{
		path: "/purchaseplan",
		component: purchaseplan,
	},

	{
		path: "/deletionpolicy",
		component: deletionpolicy,
	},

	{
		path: "/privacypolicy",
		component: privacypolicy,
	},

	{ path: "/:pathMatch(.*)*", redirect: "/" },

	{
		path: "/AddRemoverPlan",
		name: "AddRemoverPlan",
		component: AddRemoverPlan,
	},

	// Temporary Plan Payment
	// {
	// 	path: "/TemporaryPlans",
	// 	name: "TemporaryPlans",
	// 	component: Temporary_Plan_Payment,
	// },
	// {
	// 	path: "/TemporaryPlans",
	// 	name: "TemporaryPlans",
	// 	component: Temporary_Plan_Design,
	// },
	// {
	// 	path: "/TemporaryPlansBilling",
	// 	name: "TemporaryPlansBilling",
	// 	component: Temporary_Plan_Billing_Design,
	// },

	{
		path: "/LogoPayment",
		name: "LogoPayment",
		component: LogoPayment,
	},
	{
		path: "/VerifyEmail",
		name: "VerifyEmail",
		component: VerifyEmail,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
import store from "@/store/index";

// const app = createApp(App);
createApp(App).use(router).use(store).mount("#app");

// app.use(router);
// app.use(Vuex);
// app.mount("#app");

// <!-- Google tag (gtag.js) -->
// {
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-FJ2LP6N0N1"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-FJ2LP6N0N1');
// </script>
// }
