import { createApp } from "vue";
import Vuex from "vuex";

const app = createApp(/* your app component */);
app.use(Vuex);

export default new Vuex.Store({
  state: {
    paymentDetails: {},
  },
  mutations: {
    setPaymentDetails(state, details) {
      state.paymentDetails = details;
    },
  },
});
